import {banksEnum, methodsEnum} from "../../enums/enums";

export interface RequisitesParamsType {
    bank?: string
    account_id?: string
    status?: string
    name?: string
    method?: string
    user_id?: string
    owner_name?: string
    number?: string
    device_id?: string
    page?: number
    page_size?: number
}

export type NamesRequisiteType = 'id'
    | "created_at"
    | "name"
    | "account_name"
    | "bank"
    | "method"
    | "number"
    | "cardholder"
    | "daily_limit"
    | "month_limit"
    | "tx_max"
    | "tx_min"
    | "status"
    | "entity"

export const requsitesOrder = ['id', "created_at", "name", "account_name", "bank", "method", "number", "cardholder", "daily_limit", "month_limit", "tx_max", "tx_min", "status", 'entity']
export type RequisitesResponseType = {
    count: number
    total_pages: number
    page: number
    page_size: number
    results: RequisitType[]
}
export type RequisitType = {
    id: number
    created_at: string
    method: methodsEnum
    name: string
    bank: banksEnum
    status: requisitesStatusesEnum
    number: string
    cardholder: string
    account_number: string
    account_name: string
    account_id: number
    device_name: string
    daily_limit: number
    month_limit: number
    tx_min: number
    tx_max: number
    hierarchy_available: boolean
}


export enum requisitesStatusesEnum {
    available = 'available',
    busy = 'busy',
    disabled = 'disabled',
    error = 'error',

}

export const requisiteStatusConst = [
    {
        name: requisitesStatusesEnum.available,
        value: requisitesStatusesEnum.available,
    }, {
        name: requisitesStatusesEnum.busy,
        value: requisitesStatusesEnum.busy,
    }, {
        name: requisitesStatusesEnum.disabled,
        value: requisitesStatusesEnum.disabled,
    }, {
        name: requisitesStatusesEnum.error,
        value: requisitesStatusesEnum.error,
    }
]
export const requsiteStatusModal = [
    {
        name: requisitesStatusesEnum.available,
        value: requisitesStatusesEnum.available,
    }, {
        name: requisitesStatusesEnum.busy,
        value: requisitesStatusesEnum.busy,
    }, {
        name: requisitesStatusesEnum.disabled,
        value: requisitesStatusesEnum.disabled,
    }, {
        name: requisitesStatusesEnum.error,
        value: requisitesStatusesEnum.error,
    }
]

export type RequisiteSettingsType = {
    id?: boolean
    created_at?: boolean
    method?: boolean
    name?: boolean
    bank?: boolean
    number?: boolean
    cardholder?: boolean
    account_number?: boolean
    account_name?: boolean
    device_name?: boolean
    daily_limit?: boolean
    month_limit?: boolean
    tx_min?: boolean
    tx_max?: boolean
    hierarchy_available?: boolean
    status?: boolean
    entity?: boolean
}
export type RequisiteColumnSizeType = {
    id: number
    created_at: number
    method: number
    name: number
    bank: number
    number: number
    cardholder: number
    account_number: number
    account_name: number
    device_name: number
    daily_limit: number
    month_limit: number
    tx_min: number
    tx_max: number
    hierarchy_available: number
    status: number
    entity: number
}
export const RequisitesSettingsState = {
    id: true,
    created_at: true,
    method: true,
    name: true,
    bank: true,
    status: true,
    number: true,
    cardholder: true,
    account_number: true,
    account_name: true,
    device_name: true,
    daily_limit: true,
    month_limit: true,
    tx_min: true,
    tx_max: true,
    hierarchy_available: true,
}
export type AddRequisiteParamsType = {
    method?: string
    name?: string
    number?: string
    cardholder?: string
    account_number?: string
    account_id?: number | null
    daily_limit?: number | null
    month_limit?: number | null
    tx_min?: number | null
    tx_max?: number | null
}
export type EditRequisiteParamsType = {
    method?: string
    status?: requisitesStatusesEnum
    name?: string
    number?: string
    cardholder?: string
    account_number?: string
    account_id?: number | null
    daily_limit?: number | null
    month_limit?: number | null
    tx_min?: number | null
    tx_max?: number | null
}
