import {StatisticsType} from "../../constants/Examples";

export type StatisticsParamsType = {
    account_id?: number | string
    bank?: string,
    device_id?: string
    end_date?: string
    method?: string
    page?: number
    page_size?: number
    payment_detail?: string
    start_date?: string
    type?: string
    user_id?: string
}


export type StatisticSettingType = {
    date?: string
    orders_count?: number
    success_orders_count?: number
    pending_orders_count?: number
    success_amount_usdt?: number
    success_amount_rub?: number
    conversion?: number
    disputes_count?: number
    disputes_amount_usdt?: number
    disputes_amount_rub?: number
    frozen_balance?: number
}
export type StatisticSettingBooleanType = {
    date: boolean
    orders_count: boolean
    success_orders_count: boolean
    pending_orders_count: boolean
    success_amount_usdt: boolean
    success_amount_rub: boolean
    conversion: boolean
    disputes_count: boolean
    disputes_amount_usdt: boolean
    disputes_amount_rub: boolean
    frozen_balance: boolean
}

export type NamesStatisticsType =
    'date'
    | 'orders_count'
    | 'success_orders_count'
    | 'pending_orders_count'
    | 'success_amount_usdt'
    | 'success_amount_rub'
    | 'conversion'
    | 'disputes_count'
    | 'disputes_amount_rub'
    | 'disputes_amount_usdt'
    | 'frozen_balance'

export const statisticsColumnOrder = ['date', 'orders_count', 'success_orders_count', 'pending_orders_count', 'success_amount_usdt', 'success_amount_rub', 'conversion', 'disputes_count', 'disputes_amount_rub', 'disputes_amount_usdt', 'frozen_balance']

export const StatisticSettingsState = {
    date: true,
    orders_count: true,
    success_orders_count: true,
    pending_orders_count: true,
    success_amount_usdt: true,
    success_amount_rub: true,
    conversion: true,
    disputes_count: true,
    disputes_amount_usdt: true,
    disputes_amount_rub: true,
    frozen_balance: true,
}
export type StatisticsResponseType = {
    count: number
    total_pages: number
    page: number,
    page_size: number
    results: StatisticsType[],
    total: StatisticSettingType[],
}
export type StatisticsColumnSizesType = {
    date: number
    orders_count: number
    success_orders_count: number
    pending_orders_count: number
    success_amount_usdt: number
    success_amount_rub: number
    conversion: number
    disputes_count: number
    disputes_amount_usdt: number
    disputes_amount_rub: number
    frozen_balance: number
}
