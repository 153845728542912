import {Route, Routes} from "react-router-dom";
import React, {lazy} from "react";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {RoleType} from "../../state/reducers/auth-reducer";

const Statistics = lazy(() => import('../../pages/statistics/Statistics'));
const Users = lazy(() => import('../../pages/users/Users'));
const Devices = lazy(() => import('../../pages/devices/Devices'));
const Accounts = lazy(() => import('../../pages/accounts/Accounts'));
const Requisites = lazy(() => import('../../pages/requisites/Requisites'));
const Transactions = lazy(() => import('../../pages/transactions/Transactions'));
const Registers = lazy(() => import('../../pages/registers/Registers'));
const Disputes = lazy(() => import('../../pages/disputes/Disputes'));
const Orders = lazy(() => import('../../pages/orders/Orders'));
const SettingsPage = lazy(() => import('../../pages/settings/Settings'));

const MainContent = () => {
    const role = useSelector<AppRootStateType, string | null>(state => state.auth.role);


    return (
        <>
            <Routes>
                {
                    role !== 'support'
                        ? <>
                            {
                                role !== 'user' && <Route path={'/users'} element={<Users/>}/>
                            }
                            <Route path={'/statistics'} element={<Statistics/>}/>
                            <Route path={'/devices'} element={<Devices/>}/>
                            <Route path={'/accounts'} element={<Accounts/>}/>
                            <Route path={'/payment_details'} element={<Requisites/>}/>
                            <Route path={'/transactions'} element={<Transactions/>}/>
                            <Route path={'/registers'} element={<Registers/>}/>
                            <Route path={'/settings'} element={<SettingsPage/>}/>
                            <Route path={'/orders'} element={<Orders/>}/>
                            <Route path={'/disputes'} element={<Disputes/>}/>
                        </>
                        : <>
                            <Route path={'/orders'} element={<Orders/>}/>
                            <Route path={'/disputes'} element={<Disputes/>}/>
                        </>
                }
                <Route path="*" element={<div>not found</div>}/>
            </Routes>
        </>
    )
}
export default MainContent