import style from './CopyComponent.module.scss';
import {ReactComponent as Copy} from "../../assets/images/Copy.svg";
import {FC, useState} from "react";
import {clearTimeout} from "node:timers";

interface ICopy {
    value: string;
}

export const CopyComponent: FC<ICopy> = ({value}) => {
    const [copied, setCopied] = useState(false)

    const copyLink = async () => {
        await navigator.clipboard.writeText(value);
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1500)
    }

    return (
        <div className={style.Container}>
            <Copy onClick={copyLink} className={style.Copy}/>
            { <span className={`${style.Text} ${copied && style.VisibleText}`}>Скопировано !</span>}
        </div>

    )
}