import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {TextFieldCustom} from "../textField/TextField";

type PropsType = {
    formik: any
}

export const NameFilter: FC<PropsType> = ({formik}) => {
    const {t} = useTranslation();

    return (
        <>
            <TextFieldCustom title={t("filters.name")} formik={formik} formikValue={'name'}/>
        </>
    )
}