import style from "./OrderModal.module.scss";
import {Modal, Table, TableBody, TableCell, TableRow} from "@mui/material";
import {ReactComponent as Cross} from "../../../assets/images/cross.svg";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {AppRootStateType} from "../../../state/store";
import * as Yup from "yup";
import {useSelector} from "react-redux";
import {modalsActions} from "../../../state/reducers/modals-reducer";
import {useFormik} from "formik";
import {useEffect} from "react";
import {fetchDefiniteOrderTC, setOrdersChangesTC} from "../../../state/reducers/orders-reducer";
import {orderStatusesForModal, OrderType} from "../../../state/models/OrderResponse";
import {BasicSelect} from "../../../components/basicSelect/BasicSelect";
import {CopyComponent} from "../../../components/copy/CopyComponent";
import {useTranslation} from "react-i18next";
import {StatusFilter} from "../../../components/filters/StatusFilter";

const VALIDATION_SCHEMA = Yup.object({
    status: Yup.string().required('Поле обязательно'),
});


export const OrderStatusModal = () => {
    const state = useSelector<AppRootStateType, string | null>(state => state.modals.orderStatus);
    const order = useSelector<AppRootStateType, OrderType | null>(state => state.orders.definiteOrder);
    const dispatch = useAppDispatch();
    const INITIAL_FORM_STATE = {
        status: '',
    };

    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(modalsActions.changeStateOfOrderStatus(null));
    }
    const formik = useFormik({
        initialValues: INITIAL_FORM_STATE,
        validationSchema: VALIDATION_SCHEMA,
        onSubmit: async (values, action) => {
            if (state) {
                dispatch(setOrdersChangesTC({
                    id: state, status: values.status
                }))
                closeModalHandler()
            }
        }
    })

    useEffect(() => {
        if (state) {
            dispatch(fetchDefiniteOrderTC(state))
        }
    }, [state]);
    const {t} = useTranslation();

    return (
        <Modal className={style.WrapperModal}
               open={!!state}
               style={{zIndex: '9999999999'}}>
            <form className={style.Container} onSubmit={formik.handleSubmit}>
                <div className={style.HeaderContainer}>
                    <h3>{t('orderAction.title')}</h3>
                    <button>
                        <Cross onClick={closeModalHandler}/>
                    </button>
                </div>
                <Table className={style.Table}>
                    <TableBody className={style.TableBody}>
                        <TableRow>
                            <TableCell>
                                {t('orderAction.type')}
                            </TableCell>
                            <TableCell>
                                {order?.type}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t('orderAction.idTransaction')}
                            </TableCell>
                            <TableCell>
                                <div className={style.CopyBlock}>
                                    {order?.id}
                                    {order?.id && <CopyComponent value={order?.id}/>}
                                </div>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t('orderAction.amountTransaction')}
                            </TableCell>
                            <TableCell>
                                {order?.rub_amount}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {t('orderAction.prevStatus')}:
                            </TableCell>
                            <TableCell>
                                <div className={style.Status}>
                                    {order?.status}
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <StatusFilter formik={formik} items={orderStatusesForModal}/>
                <div className={style.BtnsContainer}>
                    <button className={style.CancelBtn} type={'button'} onClick={closeModalHandler}>
                        {t('cancelBtn')}
                    </button>
                    <button
                        type={'submit'}
                        className={`${style.ApplyBtn} ${(!formik.isValid || !formik.dirty) && style.BtnApplyDisabled}`}
                        disabled={!formik.isValid || !formik.dirty}>
                        {t('saveBtn')}
                    </button>
                </div>
            </form>
        </Modal>
    )
}