import {AxiosResponse} from "axios";
import {$authApi} from "../http";
import {AuthResponse} from "../models/AuthResponse";

export type LoginType = {
    username: string
    password: string
}


export const AuthService = {
    login(data: LoginType): Promise<AxiosResponse<AuthResponse>> {
        return $authApi.post('/login', data)
    },
    logout(refresh_token: string | null): Promise<void> {
        return $authApi.get('/logout', {
            headers: {Authorization: `Token ${refresh_token || 'lup lup lup'}`}
        })
    }
}

