import "./SelectLanguages.scss";
import {FormControl, MenuItem, Select, SelectChangeEvent} from "@mui/material"
import {useState} from "react";
import {ReactComponent as RuLogo} from "../../assets/images/ruLang.svg";
import {ReactComponent as EngLogo} from "../../assets/images/EngLogo.svg";
import {Menu} from "@mui/icons-material";
import {useTranslation} from "react-i18next";

const languages = [
    {
        'value': 'ru',
        'logo': <RuLogo/>
    },
    {
        'value': 'en',
        'logo': <EngLogo/>
    }
]

export const SelectLanguages = () => {
    const {t, i18n} = useTranslation();

    const changeLanguage = (language: 'ru' | 'en') => {
        i18n.changeLanguage(language);
    };

    const [lang, setLang] = useState(localStorage.getItem('lang') || 'ru');
    const [open, setOpen] = useState<boolean>(false);

    const handleChange = (event: SelectChangeEvent) => {
        setLang(event.target.value);
        localStorage.setItem('lang', event.target.value)
    };


    return (
        <div className={'WrapperSelectLanguages'}>
            <FormControl fullWidth>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={lang}
                    onChange={handleChange}
                    open={open}
                    onClose={() => setOpen(!open)}
                    onOpen={() => setOpen(!open)}
                    className={`${'Select'} ${open && 'SelectOpened'}`}
                    renderValue={(selected: string) => {
                        const yl = languages.filter(i => i.value === selected)
                        return (
                            <div className={'ValueSelected'}>
                                {yl[0].logo}
                                <p>{yl[0].value}</p>
                            </div>
                        )
                    }}
                >
                    <MenuItem value={'ru'} className={'MenuItem'} onClick={() => changeLanguage('ru')}>
                        <RuLogo className={'LangLogo'}/>
                        Русский
                    </MenuItem>
                    <MenuItem value={'en'} className={'MenuItem'} onClick={() => changeLanguage('en')}>
                        <EngLogo className={'LangLogo'}/>
                        English
                    </MenuItem>
                </Select>
            </FormControl>
        </div>
    )
}