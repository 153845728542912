import {AnyAction, combineReducers} from "redux";
import {configureStore, ThunkAction, ThunkDispatch} from "@reduxjs/toolkit";
import {appReducer} from "./reducers/appReducer";
import {authReducer} from "./reducers/auth-reducer";
import {usersReducer} from "./reducers/users-reducer";
import {settingsReducer} from "./reducers/setting-reducer";
import {statisticsReducer} from "./reducers/statistics-reducer";
import {deviceReducer} from "./reducers/device-reducer";
import {accountReducer} from "./reducers/accounts-reducer";
import {requisiteReducer} from "./reducers/requisites-reducer";
import {transactionReducer} from "./reducers/transactions-reducer";
import {registerReducer} from "./reducers/register-reducer";
import {disputeReducer} from "./reducers/dispute-reducer";
import {orderReducer} from "./reducers/orders-reducer";
import {balanceReducer} from "./reducers/balance-reducer";
import {profileReducer} from "./reducers/profile-reducer";
import {modalsReducer} from "./reducers/modals-reducer";

export const rootReducer = combineReducers({
    auth: authReducer,
    app: appReducer,
    users: usersReducer,
    settings: settingsReducer,
    statistics: statisticsReducer,
    devices: deviceReducer,
    accounts: accountReducer,
    requisites: requisiteReducer,
    transactions: transactionReducer,
    registers: registerReducer,
    disputes: disputeReducer,
    orders: orderReducer,
    balance: balanceReducer,
    profile: profileReducer,
    modals: modalsReducer,
})
export type AppRootStateType = ReturnType<typeof rootReducer>

export const store = configureStore({
    reducer: rootReducer
})

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppRootStateType, unknown, AnyAction>;

export type AppDispatch = ThunkDispatch<AppRootStateType, unknown, AnyAction>;

// @ts-ignore
window.store = store