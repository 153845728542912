import {courseSourceEnum} from "../../enums/enums";

export type UserType = {
    id: number
    date_joined: string
    last_login: string
    username: string
    address: string
    status: string
    daily_limit: number
    rate_source: courseSourceEnum
    reward_payin: number
    reward_payout: number
    reward_payout_cross_bank: number
    merchant_id: string[]
    balance: number
    frozen_balance: number
    role: "admin" | "support" | "user"
    last_login_ip: string
    last_login_os: string
    last_login_browser: string
}
export type UserUpdatesStateType = {
    id?: number
    date_joined?: string
    last_login?: string
    username?: string
    address?: string
    status?: string
    daily_limit?: number
    rate_source?: courseSourceEnum
    reward_payin?: number
    reward_payout?: number
    reward_payout_cross_bank?: number
    merchant_id?: string[]
    balance?: number
    frozen_balance?: number
    role?: "admin" | "support" | "user"
    last_login_ip?: string
    last_login_os?: string
    last_login_browser?: string
}
export type Ttype = {
    address?: string,
    balance?: string,
    daily_limit?: string,
    date_joined?: string,
    frozen_balance?: string,
    id?: string,
    last_login?: string,
    rate_source?: string,
    reward_payin?: string,
    reward_payout?: string,
    reward_payout_cross_bank?: string,
    status?: string,
    username?: string,
    role?: "admin" | "support" | "user"
}
export type UserColumnSizesType = {
    id: number
    date_joined: number
    last_login: number
    username: number
    address: number
    status: number
    daily_limit: number
    rate_source: number
    reward_payin: number
    reward_payout: number
    reward_payout_cross_bank: number
    merchant_id: number
    balance: number
    frozen_balance: number
    role: number
    entity: number
}
export const UserSettingsState = {
    id: true,
    date_joined: true,
    last_login: true,
    username: true,
    address: true,
    status: true,
    daily_limit: true,
    rate_source: true,
    reward_payin: true,
    reward_payout: true,
    reward_payout_cross_bank: true,
    merchant_id: true,
    balance: true,
    frozen_balance: true,
    role: true,
}
export type UserSettingsType = {
    id: boolean
    date_joined: boolean
    last_login: boolean
    username: boolean
    address: boolean
    status: boolean
    daily_limit: boolean
    rate_source: boolean
    reward_payin: boolean
    reward_payout: boolean
    reward_payout_cross_bank: boolean
    merchant_id: boolean
    balance: boolean
    frozen_balance: boolean
    role: boolean
    entity?: boolean
}
export type UsersResponse = {
    count: number
    page: number
    page_size: number
    results: UserType[]
    total_pages: number
}
export type UsersFilterType = {
    merchant_id?: string
    page?: number
    page_size?: number
    rate_source?: string
    status?: string
}

export type NamesUserType =
    'id'
    | 'date_joined'
    | 'username'
    | 'last_login'
    | 'reward_payin'
    | 'reward_payout'
    | 'daily_limit'
    | 'rate_source'
    | 'address'
    | 'frozen_balance'
    | 'balance'
    | 'status'
    | 'entity'

export type SetUserParamsType = {
    username?: string
    password?: string
    merchant_id?: string[]
    daily_limit?: number | null
    rate_source?: string | null
    reward_payin?: number | null
    reward_payout?: number | null
    reward_payout_cross_bank?: number | null
    status?: string | null
}
export type EditUserStateType = {
    merchant_id: []
    daily_limit: number
    rate_source: string
    reward_payin: number
    reward_payout: number
    reward_payout_cross_bank: number
    status: string
    password: string
}