import style from "../pages/statistics/Statistics.module.scss";
import {TableCellProps} from "@mui/material/TableCell/TableCell";


export const usersSettings = [
    {value: 'id', id: 'id'},
    {value: 'date_joined', date_joined: 'date_joined'},
    {value: 'username', username: 'username'},
    {value: 'last_login', last_login: 'last_login'},
    {value: 'reward_payin', reward_payin: 'reward_payin'},
    {value: 'reward_payout', reward_payout: 'reward_payout'},
    // {
    //     title: 'Выплаты межбанк',
    //     value: 'reward_payout_cross_bank',
    //     "reward_payout_cross_bank": "reward_payout_cross_bank"
    // },
    {value: 'daily_limit', daily_limit: 'daily_limit'},
    {value: 'rate_source', rate_source: 'rate_source'},
    {value: 'address', address: 'address'},
    {value: 'frozen_balance', frozen_balance: 'frozen_balance'},
    {value: 'balance', balance: 'balance'},
    {value: 'status', status: 'status'},
] as const


export const StatisticsHeader = [
    {title: 'Дата', value: 'date', date: 'date'},
    {title: 'Ордеров', value: 'orders_count', orders_count: 'orders_count'},
    {title: 'Успешные', value: 'success_orders_count', success_orders_count: 'success_orders_count'},
    {title: 'В обработке', value: 'pending_orders_count', pending_orders_count: 'pending_orders_count'},
    {title: 'Сумма успешн, USDT', value: 'success_amount_usdt', success_amount_usdt: 'success_amount_usdt'},
    {title: 'Сумма успешн, RUB', value: 'success_amount_rub', success_amount_rub: 'success_amount_rub'},
    {title: 'Конверсия', value: 'conversion', conversion: "conversion"},
    {title: 'Диспутов', value: 'disputes_count', disputes_count: 'disputes_count'},
    {title: 'Сумма дисп., RUB', value: 'disputes_amount_rub', disputes_amount_rub: 'disputes_amount_rub'},
    {title: 'Сумма дисп., USDT', value: 'disputes_amount_usdt', disputes_amount_usdt: 'disputes_amount_usdt'},
    {title: 'Заморожено, USDT', value: 'frozen_balance', frozen_balance: 'frozen_balance'},
] as const

export const AccountsHeader = [
    {title: 'ID', value: 'id', id: 'id'},
    {title: 'Добавлен', value: 'created_at', created_at: 'created_at'},
    {title: 'Название', value: 'name', name: 'name'},
    {title: 'Пользователь', value: 'username', username: 'username'},
    {title: 'Название устройства', value: 'device_name', device_name: 'device_name'},
    {title: 'Банк', value: 'bank', bank: 'bank'},
    {title: 'Кол-во реквизитов', value: 'payment_details', payment_details: "payment_details"},
    {title: 'Статус', value: 'status', status: 'status'},
] as const

export const RequisitesHeader = [
    {title: 'ID', value: 'id', id: 'id'},
    {title: 'Добавлен', value: 'created_at', created_at: 'created_at'},
    {title: 'Название', value: 'name', name: 'name'},
    {title: 'Аккаунт', value: 'account_name', account_name: 'account_name'},
    {title: 'Банк', value: 'bank', bank: 'bank'},
    {title: 'Метод платежа', value: 'method', method: 'method'},
    {title: 'Данные реквизита', value: 'number', number: 'number'},
    {title: 'Владелeц аккаунта', value: 'cardholder', cardholder: "cardholder"},
    {title: 'Дневной лимит, USDT', value: 'daily_limit', daily_limit: "daily_limit"},
    {title: 'Месячный лимит,  USDT', value: 'month_limit', month_limit: "month_limit"},
    {title: 'Макс. пополнение, USDT', value: 'tx_max', tx_max: "tx_max"},
    {title: 'Мин. пополнение, USDT', value: 'tx_min', tx_min: "tx_min"},
    {title: 'Статус', value: 'status', status: 'status'},
] as const;

export const TransactionsHeader = [
    {title: 'Дата и время', value: 'transaction_timestamp', transaction_timestamp: 'transaction_timestamp'},
    {title: 'ID', value: 'id', id: 'id'},
    {title: 'Исходящий адресс', value: 'source_address', source_address: 'source_address'},
    {title: 'Входящий адресс', value: 'target_address', target_address: 'target_address'},
    {title: 'Hash', value: 'tx_hash', tx_hash: 'tx_hash'},
    {title: 'Тип', value: 'type', type: 'type'},
    {title: 'Пользователь', value: 'user', type: 'user'},
    {title: 'Сообщение', value: 'comment', comment: 'comment'},
    {title: 'Сумма, USDT', value: 'amount', amount: 'amount'},
    {title: 'Статус', value: 'status', user: 'status'},
] as const;

export const RegistersHeader = [
    {title: 'ID', value: 'id', id: 'id'},
    {title: 'Название реестра', value: 'name', name: 'name'},
    {title: 'Тип реестра', value: 'type', type: 'type'},
    {title: 'Параметры реестра', value: 'params', params: 'params'},
    {title: 'Создан', value: 'created_at', created_at: 'created_at'},
    {title: 'Завершен', value: 'finalized_at', finalized_at: 'finalized_at'},
    {title: 'Статус', value: 'status', status: 'status'},
] as const;

export const DisputesHeader = [
    {title: 'Создан', value: 'created_at', created_at: 'created_at'},
    {title: 'Обновлен', value: 'updated_at', updated_at: 'updated_at'},
    {title: 'Менеджер', value: 'manager_username', manager_username: 'manager_username'},
    {title: 'Пользователь', value: 'trader_username', trader_username: 'trader_username'},
    {title: 'ID ордера (родитель)', value: 'order_id', order_id: 'order_id'},
    {title: 'Создан (родитель)', value: 'order_created_at', order_created_at: 'order_created_at'},
    {title: 'Сумма ордера (родитель), USDT', value: 'order_amount', order_amount: 'order_amount'},
    {title: 'Статус ордера (родитель)', value: 'order_status', order_status: 'order_status'},
    {title: 'ID диспута', value: 'dispute_id', dispute_id: 'dispute_id'},
    {title: 'Сумма ордера диспута, USDT', value: 'dispute_amount', dispute_amount: 'dispute_amount'},
    {title: 'Статус диспута', value: 'dispute_status', dispute_status: 'dispute_status'},
    {title: 'Количество сообщений', value: 'messages_count', messages_count: 'messages_count'},
    {title: 'Количество уведомлений', value: 'alerts_count', alerts_count: 'alerts_count'},
] as const;

export const DevicesHeader = [
    {title: 'ID', value: 'id', id: 'id'},
    {title: 'Название', value: 'name', name: 'name'},
    {title: 'Добавлено', value: 'created_at', created_at: 'created_at'},
    {title: 'Приложение для приема', value: 'payin_app', payin_app: 'payin_app'},
    {title: 'Приложение для выплат', value: 'payout_app', payin_app: 'payout_app'},
    {title: 'Аккаунтов', value: 'accounts_count', accounts_count: 'accounts_count'},
    {title: 'Реквизитов', value: 'payment_details_count', payment_details_count: 'payment_details_count'},
    {title: 'Статус', value: 'status', status: 'status'},
] as const;

export const OrdersHeader = [
    {title: 'Создание', value: 'created_at', created_at: 'created_at'},
    {title: 'Исполнение', value: 'finalized_at', finalized_at: 'finalized_at'},
    {title: 'ID', value: 'id', id: 'id'},
    {title: 'Processing ID', value: 'external_id', external_id: 'external_id'},
    {title: 'Тип', value: 'type', type: 'type'},
    {title: 'Аккаунт', value: 'account_name', account_name: 'account_name'},
    {title: 'Банк', value: 'bank', bank: 'bank'},
    {title: 'Устройство', value: 'device_name', device_name: 'device_name'},
    {title: 'Ключ', value: 'device_token', device_token: 'device_token'},
    {title: 'Метод платежа', value: 'method', method: 'method'},
    {title: 'Номер реквизита', value: 'number', number: 'number'},
    {title: 'Сумма, RUB', value: 'rub_amount', rub_amount: 'rub_amount'},
    {title: 'Курс', value: 'price', price: 'price'},
    {title: 'Источник курса', value: 'rate_source', rate_source: 'rate_source'},
    {title: 'Сумма, USDT', value: 'usdt_amount', usdt_amount: 'usdt_amount'},
    {title: 'Вознаграждение общ., USDT', value: 'reward_total', reward_total: 'reward_total'},
    {title: 'Вознаграждение за межбанк, USDT', value: 'reward_crossbank', reward_crossbank: 'reward_crossbank'},
    {title: 'Вознаграждение, USDT', value: 'reward', reward: 'reward'},
    {title: 'Общая сумма, USDT', value: 'total_usdt_amount', total_usdt_amount: 'total_usdt_amount'},
    {title: 'Сообщение', value: 'comment', comment: 'comment'},
    {title: 'Статус', value: 'status', status: 'status'},
] as const;

export const statisticsHeader: Array<{
    title: string,
    tableCellProp?: TableCellProps,
    key: keyof StatisticsType,
    render?: Function
}> = [
    {
        title: 'Дата', key: '_id',
        render: (id: string,) => {
            return id && new Date(id).toLocaleDateString("ru-RU")
        },
        tableCellProp: {
            style: {textAlign: 'left'},
            className: style.BoldTableCell
        }
    },
    {
        title: 'Запросы', key: 'requestsCount', render: (id: string,) => {
            return (
                ''
                // <div>asdfghjklfdfg</div>
            )
        }
    },
    {title: 'В обработке', key: 'inProcessingCount'},
    {title: 'Успешные', key: 'successCount'},
    {title: 'Конверсия', key: 'conversion'},
    {title: 'Сумма успешн, RUB', key: 'successAmountRub'},
    {title: 'Сумма успешн, USDT', key: 'successAmountUSDT'},
    {title: 'Кол-во диспутов', key: 'disputesCount'},
    {title: 'Сумма диспутов, RUB', key: 'disputesAmountRub'},
    {title: 'Сумма диспутов, USDT', key: 'disputesAmountUSDT'},
    {title: 'Заморожено, RUB', key: 'frozenAmountRub'},
    {title: 'Заморожено, USDT', key: 'frozenAmountUSDT'},
]
export type StatisticsType = {
    _id: string
    requestsCount: number
    inProcessingCount: number
    successCount: number
    conversion: number
    successAmountRub: number
    successAmountUSDT: number
    disputesCount: number
    disputesAmountRub: number
    disputesAmountUSDT: number
    frozenAmountRub: number
    frozenAmountUSDT: number
}

export enum userStatuses {
    enabled = 'enabled',
    disabled = 'disabled',
    blocked = 'blocked',
}

export const userStatusesState = [
    {
        name: 'enabled',
        value: 'enabled',
    }, {
        name: 'disabled',
        value: 'disabled',
    }, {
        name: 'blocked',
        value: 'blocked',
    }
]
export const userStatusesForModal = [
    {
        name: 'enabled',
        value: 'enabled',
    }, {
        name: 'disabled',
        value: 'disabled',
    }, {
        name: 'blocked',
        value: 'blocked',
    }
]


export type UsersType = {
    _id: number
    createdAt: string,
    name: string
    role: string
    lastEntrance: string
    lastEntranceLoc?: string
    lastEntranceGadget?: string
    teamLead: string
    arbitrator?: number
    currencies: string
    limitUSDT: number
    balanceUSDT: number
    status: userStatuses | ''
    action: boolean
}
export const perPageDefault = 50
export const defaulFiltertObj = {
    value: 'Не выбрано', name: 'Не выбрано'
}
export const notChosen = 'Не выбрано'