import style from "./AddDevice.module.scss";
import {Modal} from "@mui/material";
import {ReactComponent as Cross} from "../../../assets/images/cross.svg";
import {TextFieldCustom} from "../../../components/textField/TextField";
import {useFormik} from "formik";
import React from "react";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../../state/store";
import {NumberTextField} from "../../../components/numberTextField/NumberTextField";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import * as Yup from "yup";
import {modalsActions} from "../../../state/reducers/modals-reducer";
import {addDeviceTC} from "../../../state/reducers/device-reducer";
import {EditDeviceParamsType} from "../../../state/models/Deviceresponse";
import {NameFilter} from "../../../components/filters/NameFilter";
import {DailyLimitFilter} from "../../../components/filters/DayliLimitFilter";
import {MonthLimitFilter} from "../../../components/filters/MonthLimitFilter";
import {ApplicationSecretKeyFilter} from "../../../components/filters/ApplicationSecretKey";
import {AutoPaySecretKeyFilter} from "../../../components/filters/AutoPaySecretKey";
import {useTranslation} from "react-i18next";


const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string().required('Поле обязательно'),
});


export const AddDeviceModal = () => {
    const state = useSelector<AppRootStateType, boolean>(state => state.modals.addDevice);
    const dispatch = useAppDispatch();
    const INITIAL_FORM_STATE = {
        name: '',
        payin_token: '',
        payout_token: '',
        telegram_id: '',
        tx_min: null,
        tx_max: null,
        daily_limit: null,
        month_limit: null,
    };


    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(modalsActions.changeStateOfAddDevice(false))
    }

    const formik = useFormik({
        initialValues: INITIAL_FORM_STATE,
        validationSchema: VALIDATION_SCHEMA,
        onSubmit: async (values, action) => {
            const applyObj: EditDeviceParamsType = {}
            if (values.payin_token !== '') {
                applyObj.payin_token = values.payin_token
            }
            if (values.payout_token !== '') {
                applyObj.payout_token = values.payout_token
            }
            if (values.telegram_id !== '') {
                applyObj.telegram_id = values.telegram_id
            }
            if (values.tx_min !== null) {
                applyObj.tx_min = values.tx_min
            }
            if (values.tx_max !== null) {
                applyObj.tx_max = values.tx_max
            }
            if (values.daily_limit !== null) {
                applyObj.daily_limit = values.daily_limit
            }
            if (values.month_limit !== null) {
                applyObj.month_limit = values.month_limit
            }
            applyObj.name = values.name
            const res = await dispatch(addDeviceTC(applyObj));

            if (!res) {
                formik.setFieldError('name', 'Устройство с таким именем уже существуем');
            } else {
                closeModalHandler();
            }
        }

    });

    const {t} = useTranslation();

    return (
        <Modal className={style.WrapperModal}
               open={state}
               style={{zIndex: '9999999999'}}>
            <form className={style.Container} onSubmit={formik.handleSubmit}>
                <div className={style.HeaderContainer}>
                    <h3>{t('addDevice.title')}</h3>
                    <button>
                        <Cross onClick={closeModalHandler}/>
                    </button>
                </div>
                <div className={style.FiltersContainer}>
                    <NameFilter formik={formik}/>
                    <TextFieldCustom title={'Telegram ID'} formik={formik} formikValue={'telegram_id'}/>
                    <AutoPaySecretKeyFilter formik={formik}/>
                    <ApplicationSecretKeyFilter formik={formik}/>
                    <div className={style.RewardBlock}>
                        <DailyLimitFilter formik={formik}/>
                        <MonthLimitFilter formik={formik}/>
                    </div>
                    <div className={style.RestrictionBlock}>
                        <p>{t('addDevice.limits')}</p>
                        <div>
                            <div>
                                <p>{t('addDevice.from')}</p>
                                <NumberTextField formikValue={'tx_min'} formik={formik}
                                                 title={''}
                                                 placeholder={t('addDevice.enterAmount')}
                                                 error={formik.errors.tx_min}/>
                            </div>
                            <div>
                                <p>{t('addDevice.to')}</p>
                                <NumberTextField formikValue={'tx_max'} formik={formik}
                                                 title={''}
                                                 placeholder={t('addDevice.enterAmount')}
                                                 error={formik.errors.tx_max}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.BtnsContainer}>
                    <button className={style.CancelBtn} type={'button'} onClick={closeModalHandler}>
                        {t('cancelBtn')}
                    </button>
                    <button
                        type={'submit'}
                        className={`${style.ApplyBtn} ${(!formik.isValid || !formik.dirty) && style.BtnApplyDisabled}`}
                        disabled={!formik.isValid || !formik.dirty}>
                        {t('saveBtn')}
                    </button>
                </div>
            </form>
        </Modal>
    )
}