import {AxiosResponse} from "axios";
import {$api} from "../http";
import {SetUserParamsType, UsersFilterType, UsersResponse, UserType} from "../models/UsersResponse";
import {getToken} from "../../utils/utils";


export const UsersService = {
    fetchUsers(params: UsersFilterType): Promise<AxiosResponse<UsersResponse>> {
        return $api.get('/users', {
            params: params,
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    setUser(params: SetUserParamsType): Promise<AxiosResponse<UserType>> {
        return $api.post('/users', params, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    editUser(id: number, params: SetUserParamsType): Promise<AxiosResponse<UserType>> {
        return $api.put(`/users/${id}`, params, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    getDefiniteUser(id: number): Promise<AxiosResponse<UserType>> {
        return $api.get(`/users/${id}`, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }

}

