import {BasicSelect} from "../basicSelect/BasicSelect";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {SettingType} from "../../state/reducers/setting-reducer";
import {useTranslation} from "react-i18next";
import MultipleSelect2 from "../multipleSelect/MultipleSelect2";

type PropsType = {
    formik: any
    type?: 'extended'
    typeOfSelect?: 'multiple' | 'basic'
}

export const UserFilter: FC<PropsType> = ({formik, type, typeOfSelect}) => {
    const {t} = useTranslation();
    const users = useSelector<AppRootStateType, SettingType[]>(state => state.settings.users);
    const defaultVal = t('notChosen')
    const defaulFiltertObj = {
        value: defaultVal, name: defaultVal
    }

    const items = users && type !== 'extended' ? [defaulFiltertObj, ...users] : users;

    return (
        <>
            {users && (typeOfSelect !== 'multiple'
                    ? <BasicSelect label={t("filters.user")} title={'user_id'} formik={formik}
                                   items={items}/>
                    : <MultipleSelect2 setFieldValue={formik.setFieldValue} title={t("filters.user")}
                                       formikValue={'user_id'}
                                       formik={formik} items={users}/>
            )}
        </>
    )
}