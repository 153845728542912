import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../../utils/error-utils";
import {
    NewOrderParamsType,
    OrderParamsType,
    OrderResponseType,
    OrderSettingsState,
    OrderSettingsType, OrderType
} from "../models/OrderResponse";
import {OrderService} from "../services/OrderService";
import {OrderFiltersForSelectType} from "../../pages/orders/ordersFilters/Ordersfilters";

type  InitialStateType = {
    filters: OrderParamsType
    filtersForSelect: OrderFiltersForSelectType
    orders: OrderResponseType
    settings: OrderSettingsType
    definiteOrder: OrderType | null
}
const initialState: InitialStateType = {
    filters: JSON.parse(localStorage.getItem('orderFilters') || JSON.stringify({})),
    filtersForSelect: JSON.parse(localStorage.getItem('orderFiltersForSelect') || JSON.stringify({})),
    settings: JSON.parse(localStorage.getItem('orderSettings') || JSON.stringify(OrderSettingsState)),
    orders: {
        count: 1,
        page: 1,
        page_size: 1,
        results: [],
        total_pages: 1,
        total: []
    },
    definiteOrder: null
}

const slice = createSlice({
    name: "orders",
    initialState: initialState,
    reducers: {
        setOrders: (state, action: PayloadAction<OrderResponseType>) => {
            state.orders = action.payload
        },
        setOrderSettings: (state, action: PayloadAction<OrderSettingsType>) => {
            state.settings = action.payload
            localStorage.setItem('orderSettings', JSON.stringify(action.payload));
        },
        setOrderFilters: (state, action: PayloadAction<OrderParamsType>) => {
            state.filters = action.payload
            localStorage.setItem('orderFilters', JSON.stringify(action.payload));
        },
        setOrderFiltersForSelect: (state, action: PayloadAction<OrderFiltersForSelectType>) => {
            state.filtersForSelect = action.payload
            localStorage.setItem('orderFiltersForSelect', JSON.stringify(action.payload));
        },
        setDefiniteOrder: (state, action: PayloadAction<OrderType>) => {
            state.definiteOrder = action.payload
        },
        setOrdersChanges: (state, action: PayloadAction<OrderType>) => {
            const index = state.orders.results.findIndex((t) => t.id === action.payload.id);
            if (index !== -1) {
                state.orders.results[index] = {...action.payload};
            }
        },

    },
});
export const orderReducer = slice.reducer;
export const orderActions = slice.actions;


export const fetchOrdersTC = (data: OrderParamsType): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        OrderService.fetchOrders(data).then((res) => {
            dispatch(orderActions.setOrders(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

export const fetchDefiniteOrderTC = (id: string): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        OrderService.getDefiniteOrders(id).then((res) => {
            dispatch(orderActions.setDefiniteOrder(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

export const setOrdersChangesTC = (data:NewOrderParamsType): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));

        OrderService.setOrderChanges(data).then((res) => {
            dispatch(orderActions.setOrdersChanges(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

