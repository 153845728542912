import {AxiosResponse} from "axios";
import {$api} from "../http";
import {getToken} from "../../utils/utils";
import {
    AddDeviceParamsType,
    DeviceParamsType,
    DeviceResponseType,
    DeviceType,
    EditDeviceParamsType
} from "../models/Deviceresponse";

export const DeviceService = {
    fetchDevices(params: DeviceParamsType): Promise<AxiosResponse<DeviceResponseType>> {
        return $api.get('/devices', {
            params: params,
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    getDefiniteDevice(id: number): Promise<AxiosResponse<DeviceType>> {
        return $api.get(`/devices/${id}`, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    addDevice(params: AddDeviceParamsType): Promise<AxiosResponse<DeviceType>> {
        return $api.post(`/devices`, params, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    editDevice(id: number, params: EditDeviceParamsType): Promise<AxiosResponse<DeviceType>> {
        return $api.put(`/devices/${id}`, params, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }

}

