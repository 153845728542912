import style from './NumberTextField.module.scss'
import {TextField} from "@mui/material";
import React, {FC} from "react";

interface TextFieldI {
    title: string
    error: string | undefined
    formik: any
    formikValue: string
    type?: 'reward'
    placeholder?:string
}

export const NumberTextField: FC<TextFieldI> = ({title, placeholder, formik, type, formikValue}) => {
    const error = (formik?.errors[formikValue] && formik?.touched[formikValue]);

    return (
        <div className={style.FilterBox}>
            <div className={style.Desc}>
                <p>
                    {title}
                </p>
            </div>
            {type && <span className={style.Procents}>%</span>}
            <TextField
                {...formik.getFieldProps(formikValue)}
                className={`${style.TextField} ${type && style.TextFieldProcents} ${error && style.TextFieldError}`}
                id="outlined-number"
                type="number"
                placeholder={placeholder}
                InputLabelProps={{
                    shrink: true,
                }}
            />
            {
                error && <p className={style.Error}>{formik?.errors[formikValue]}</p>
            }
        </div>
    )
}