import {AxiosResponse} from "axios";
import {$api} from "../http";
import {getToken} from "../../utils/utils";
import {BalanceResponseType} from "../models/BalanceResponse";


export type BalanceParamsTYpe = {
    user_id:number
}
export const BalanceService = {
    fetchBalances(params?: BalanceParamsTYpe): Promise<AxiosResponse<BalanceResponseType>> {
        return $api.get('/users/balance', {
            params,
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }

}

