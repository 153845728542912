import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../../utils/error-utils";
import {BalanceParamsTYpe, BalanceService} from "../services/BalanceService";
import {BalanceResponseType} from "../models/BalanceResponse";

type  InitialStateType = {
    state: {
        balance: number
        frozen_balance: number
        total: number,
        definiteUserBalance:null | number
    }
}
const initialState: InitialStateType = {
    state: {
        balance: JSON.parse(localStorage.getItem('balance') || JSON.stringify(0)),
        frozen_balance: JSON.parse(localStorage.getItem('frozen_balance') || JSON.stringify(0)),
        total: JSON.parse(localStorage.getItem('total') || JSON.stringify(0)),
        definiteUserBalance:null
    }
}

const slice = createSlice({
    name: "balance",
    initialState: initialState,
    reducers: {
        setBalance: (state: InitialStateType, action: PayloadAction<BalanceResponseType>) => {
            state.state.frozen_balance = action.payload.frozen_balance
            state.state.balance = action.payload.balance
            state.state.total = action.payload.balance + action.payload.frozen_balance
            localStorage.setItem('balance', JSON.stringify(action.payload.balance))
            localStorage.setItem('frozen_balance', JSON.stringify(action.payload.frozen_balance))
            localStorage.setItem('total', JSON.stringify(state.state.total))
        },
        setDefiniteUserBalance:(state: InitialStateType, action: PayloadAction<BalanceResponseType>) => {
            state.state.definiteUserBalance = action.payload.balance
        }
    },
});
export const balanceReducer = slice.reducer;
export const balanceActions = slice.actions;


export const fetchBalanceTC = (): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        BalanceService.fetchBalances().then((res) => {
            dispatch(balanceActions.setBalance(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };
export const fetchDefiniteBalanceTC = (id: number): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        BalanceService.fetchBalances({user_id:id}).then((res) => {
            dispatch(balanceActions.setDefiniteUserBalance(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

