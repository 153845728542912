import {Modal} from "@mui/material";
import style from './ConfirmModal.module.scss';
import {ReactComponent as Cross} from "../../assets/images/cross.svg";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {ConfirmModalType, modalsActions} from "../../state/reducers/modals-reducer";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useTranslation} from "react-i18next";


export const ConfirmModal = () => {

    const state = useSelector<AppRootStateType, ConfirmModalType | null>(state => state.modals.confirm);
    const confirm = useSelector<AppRootStateType, any>(state => state.modals.confirm?.onConfirm);
    const dispatch = useAppDispatch();

    const closeModal = () => {
        if (state?.type !== 'block') {
            dispatch(modalsActions.setConfirmModal(null))

        } else {
            dispatch(confirm)
            dispatch(modalsActions.setConfirmModal(null))
        }

    }
    const toggleConfirmModal = () => {
        if (state?.type !== 'block') {
            dispatch(confirm)
            dispatch(modalsActions.setConfirmModal(null))
        } else {
            dispatch(modalsActions.setConfirmModal(null))
        }
    }
    
    const {t} = useTranslation();
    return (
        <Modal className={style.WrapperModal}
               open={!!state}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description"
               style={{zIndex: '9999999999'}}>
            <div className={style.Container}>
                <div className={style.Header}>
                    <h4>
                        {t('admitTitle')}
                    </h4>
                    <Cross onClick={() => dispatch(modalsActions.setConfirmModal(null))} className={style.Cross}/>
                </div>
                <div className={style.Desc}>
                    <p>
                        {state?.title}
                    </p>
                    <p>
                        {state?.desc}
                    </p>
                </div>
                <div className={style.BtnsWrapper}>
                    <button
                        className={`${style.AcionBtn} ${state?.type === 'block' && style.CancelActionBtn}`}
                        onClick={closeModal}>
                        {state?.closeBtn}
                    </button>
                    <button className={style.CancelBtn} onClick={toggleConfirmModal}>{state?.confirmBtn}</button>
                </div>
            </div>
        </Modal>
    )
}