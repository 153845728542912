import {AxiosResponse} from "axios";
import {$api} from "../http";
import {getToken} from "../../utils/utils";
import {
    AddRequisiteParamsType, EditRequisiteParamsType,
    RequisitesParamsType,
    RequisitesResponseType,
    RequisitType
} from "../models/RequisitesResponse";


export default class RequisitesService {
    static async fetchRequisites(params: RequisitesParamsType): Promise<AxiosResponse<RequisitesResponseType>> {
        return $api.get(`/payment_details`, {
            params,
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }

    static async getDefiniteRequisite(id: number): Promise<AxiosResponse<RequisitType>> {
        return $api.get(`/payment_details/${id}`, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }

    static async addRequisite(params: AddRequisiteParamsType): Promise<AxiosResponse<RequisitType>> {
        return $api.post(`/payment_details`, params, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }
    static async editRequisite(id: number, params: EditRequisiteParamsType): Promise<AxiosResponse<RequisitType>> {
        return $api.put(`/payment_details/${id}`, params, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }
}


