import {ChangeEvent} from "react";
import {Bounce, toast} from "react-toastify";

export const getToken = () => {
    return localStorage.getItem('token')
}
export const setToken = (data: string) => {
    return localStorage.setItem('token', data)
}
export const removeToken = () => {
    return localStorage.removeItem('token')
}
export const setRoleToStorage = (role:string) => {
    return localStorage.setItem('role', role)
}
export const getRole = () => {
    return localStorage.getItem('role')
}
export const deleteRole = () => {
    return localStorage.removeItem('role')
}

export const showSuccessToast = (message: string) => {
    toast.success(message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });
}

export function numberWithSpaces(x: number | string) {
    let y = x.toString()
    var parts = y.split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export const prettifyAmount = (amount: number) => numberWithSpaces((amount / 100).toFixed(2))

function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
}

export function formatDate(date: Date | string) {
    const perfDate = new Date(date)

    return [
        padTo2Digits(perfDate.getDate()),
        padTo2Digits(perfDate.getMonth() + 1),
        perfDate.getFullYear(),
    ].join('.');
}

export function formatDateForApi(date: Date | string | number) {
    const perfDate = new Date(date)

    return [
        perfDate.getFullYear(),
        padTo2Digits(perfDate.getMonth() + 1),
        padTo2Digits(perfDate.getDate())
    ].join('-');
}

export function formatTime(date: Date | string | number) {
    const perfDate = new Date(date)

    return [
        padTo2Digits(perfDate.getHours() ),
        padTo2Digits(perfDate.getMinutes()),
        padTo2Digits(perfDate.getSeconds()),
    ].join(':');
}

export function formatDateForDownload(date: Date | string,) {
    const perfDate = new Date(date)
    return [
        perfDate.getFullYear(),
        padTo2Digits(perfDate.getMonth() + 1),
        padTo2Digits(perfDate.getDate()),
    ].join('-');
}

const onChangeTgIdValue = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.length === 0) {
        console.log('required')
    }
    if (e.currentTarget.value.match(/(?!-)\D/)) {
        e.preventDefault()
        console.log('the field must contain only numbers')
    }
    // field.onChange(e.currentTarget.value.replace(/(?!-)\D/, ''))
    // formik.handleChange(e.currentTarget.value.replace(/(?!-)\D/, ''))
}
export const copyLink = async (title: string) => {
    await navigator.clipboard.writeText(title);
    setTimeout(() => {
        console.log('copied')
    }, 300)
    console.log('Uncopied')
}
export const formatArrOfHeaders = (headers: any) => {
    const obj = Object.fromEntries(headers.map((item: any) => [item.value, item.value]));
    const urr = [obj];
    return urr
}
export const prettyFyNumberToCardType = (val: number) => {
    return val.toString().replace(/\d{4}(?=.)/g, '$& ')
}