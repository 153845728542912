import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {TextFieldCustom} from "../textField/TextField";

type PropsType = {
    formik: any
}

export const ApplicationSecretKeyFilter: FC<PropsType> = ({formik}) => {
    const {t} = useTranslation();

    return (
        <>
            <TextFieldCustom title={t('addDevice.payin_token')}
                             formik={formik}
                             formikValue={'payin_token'}/>
        </>
    )
}