import {Dispatch} from "redux";
import {removeToken} from "./utils";
import {appActions} from "../state/reducers/appReducer";
import {Bounce, toast} from "react-toastify";
import {authActions} from "../state/reducers/auth-reducer";
import {AxiosError} from "axios";


export const handleServerNetworkError = (error: AxiosError, dispatch: Dispatch) => {
    toast.error(error.message, {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        transition: Bounce,
    });
    if (error.response) {
        if (error.response.status === 401) {
            removeToken()
            dispatch(authActions.setIsLoggedIn({isLoggedIn: false}));
        }
        if (error.response.status === 403) {
            removeToken()
            dispatch(authActions.setIsLoggedIn({isLoggedIn: false}));
        }
        // removeToken()
        // dispatch(authActions.setIsLoggedIn({isLoggedIn: false}));
    }

    dispatch(appActions.setAppError({error: error.message ? error.message : "Some error occurred"}));
    dispatch(appActions.setAppStatus({status: "failed"}));
};

export type ResponseType<D = {}> = {
    resultCode: number;
    messages: Array<string>;
    data: D;
};