import React, {memo} from "react";
import {NavLink} from "react-router-dom";
import {ReactComponent as Logo} from "../../assets/images/logo.svg";
import {ReactComponent as LogOut} from "../../assets/images/logout.svg";
import {ReactComponent as Settings} from "../../assets/images/settings.svg";
import style from './Navbar.module.scss';
import {SelectLanguages} from "../selectLanguages/SelectLanguages";
import {BalanceMenu} from "../balanceMenu/BalanceMenu";
import {logoutTC} from "../../state/reducers/auth-reducer";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {useTranslation} from "react-i18next";

export const NavbarComponent = memo(function NavbarComponent() {
    const role = useSelector<AppRootStateType, string | null>(state => state.auth.role);
    const dispatch = useAppDispatch();

    const {t} = useTranslation();

    return (
        <header>
            <div className={style.Container}>
                <div className={style.AsideLeftBlock}>
                    <Logo/>
                    <SelectLanguages/>
                </div>

                <nav>
                    {
                        role !== 'support'
                            ? <>
                                {
                                    role !== 'user' && <NavLink to={'users'}
                                                                className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>{t("navbar.users")}</NavLink>
                                }
                                <NavLink to={'statistics'}
                                         className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>{t("navbar.statistics")}</NavLink>
                                <NavLink to={'accounts'}
                                         className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>{t("navbar.accounts")}</NavLink>
                                <NavLink to={'devices'}
                                         className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>{t("navbar.devices")}</NavLink>
                                <NavLink to={'payment_details'}
                                         className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>{t("navbar.payment_details")}</NavLink>
                                <NavLink to={'orders'}
                                         className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>{t("navbar.orders")}</NavLink>
                                <NavLink to={'transactions'}
                                         className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>{t("navbar.transactions")}</NavLink>
                                <NavLink to={'registers'}
                                         className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>{t("navbar.registers")}</NavLink>

                                <NavLink to={'disputes'}
                                         className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>{t("navbar.disputes")}</NavLink>
                            </>
                            : <>
                                <NavLink to={'orders'}
                                         className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>{t("navbar.orders")}</NavLink>
                                <NavLink to={'disputes'}
                                         className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>{t("navbar.disputes")}</NavLink>
                            </>
                    }
                </nav>

                <div className={style.AsideBlock}>
                    <BalanceMenu/>
                    <NavLink to={'settings'}
                             className={({isActive}) => (isActive ? style.ActiveLink : style.InActiveLink)}>
                        <Settings/>
                    </NavLink>
                    <button onClick={() => {
                        dispatch(logoutTC())
                    }}>

                        <LogOut/>
                    </button>
                </div>
            </div>
        </header>
    )
})
