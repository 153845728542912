import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../../utils/error-utils";
import {ProfileService} from "../services/ProfileService";
import {ProfileResponseType} from "../models/ProfileResponse";
import {removeToken} from "../../utils/utils";
import {authActions} from "./auth-reducer";

type  InitialStateType = {
    state: ProfileResponseType
}
const initialState: InitialStateType = {
    state: {
        date_joined: '',
        username: '',
        address: '',
        rate_source_url: '',
        daily_limit: 0,
        rate_source: '',
        reward_payin: 0,
        reward_payout: 0,
        reward_payout_cross_bank: 0,
    }
}

const slice = createSlice({
    name: "profile",
    initialState: initialState,
    reducers: {
        setProfile: (state: InitialStateType, action: PayloadAction<ProfileResponseType>) => {
            state.state = action.payload
        },
    },
});
export const profileReducer = slice.reducer;
export const profileActions = slice.actions;


export const fetchProfileTC = (): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        ProfileService.fetchProfiles().then((res) => {
            dispatch(profileActions.setProfile(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

export const setNewPassTC = (password: string): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        ProfileService.setNewPass(password).then((res) => {
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            removeToken()
            dispatch(authActions.setIsLoggedIn({isLoggedIn: false}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

