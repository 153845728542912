import {BasicSelect} from "../basicSelect/BasicSelect";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {AccountSettingType, SettingType} from "../../state/reducers/setting-reducer";
import MultipleSelect2 from "../multipleSelect/MultipleSelect2";
import {accountStatuses} from "../../state/models/AccountResponse";

type PropsType = {
    formik: any
    items: SettingType[] | AccountSettingType[]
    type?:'extended'
    typeOfSelect?:'multiple' | 'basic'
}

export const StatusFilter: FC<PropsType> = ({formik, items,typeOfSelect, type}) => {
    const {t} = useTranslation();
    const defaultVal = t('notChosen')
    const defaulFiltertObj = {
        value: defaultVal, name: defaultVal
    }
    const itemsarr = type !== 'extended'?  [defaulFiltertObj, ...items] : items

    return (
        <>
            {typeOfSelect !== 'multiple' ? <BasicSelect label={t("filters.status")} title={'status'} formik={formik} items={itemsarr}/>
                : <MultipleSelect2 setFieldValue={formik.setFieldValue} title={t("filters.status")} formikValue={'status'}
                                 formik={formik} items={items}/>}
        </>
    )
}