import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../../utils/error-utils";
import {
    DisputeParamsType,
    DisputeResponseType,
    DisputeSettingsState,
    DisputeSettingsType, DisputeType, EditDisputeParamsType, NewDisputeParamsType
} from "../models/DisputeResponse";
import {DisputeService} from "../services/DisputeService";
import {AxiosError} from "axios";
import { DisputesfiltersForSelectsType } from "../../pages/disputes/disputesFilters/Disputesfilters";

type  InitialStateType = {
    filters: DisputeParamsType
    filtersForSelect: DisputesfiltersForSelectsType
    disputes: DisputeResponseType
    settings: DisputeSettingsType
}
const initialState: InitialStateType = {
    filters: JSON.parse(localStorage.getItem('disputeFilters') || JSON.stringify({})),
    filtersForSelect: JSON.parse(localStorage.getItem('disputeFiltersForSelects') || JSON.stringify({})),
    settings: JSON.parse(localStorage.getItem('disputeSettings') || JSON.stringify(DisputeSettingsState)),
    disputes: {
        count: 1,
        page: 1,
        page_size: 1,
        results: [],
        total_pages: 1
    }
}

const slice = createSlice({
    name: "disputes",
    initialState: initialState,
    reducers: {
        setDisputes: (state, action: PayloadAction<DisputeResponseType>) => {
            state.disputes = action.payload
        },
        setDisputeSettings: (state, action: PayloadAction<DisputeSettingsType>) => {
            state.settings = action.payload
            localStorage.setItem('disputeSettings', JSON.stringify(action.payload));
        },
        setDisputeFilters: (state, action: PayloadAction<DisputeParamsType>) => {
            state.filters = action.payload
            localStorage.setItem('disputeFilters', JSON.stringify(action.payload));
        },
        setDisputeFiltersForSelect: (state, action: PayloadAction<DisputesfiltersForSelectsType>) => {
            state.filtersForSelect = action.payload
            localStorage.setItem('disputeFiltersForSelects', JSON.stringify(action.payload));
        },
        setNewDispute: (state, action: PayloadAction<DisputeType>) => {
            state.disputes.results.unshift(action.payload)
        },
        setDisputeUpdates: (state: InitialStateType, action: PayloadAction<DisputeType>) => {
            const index = state.disputes.results.findIndex((t) => t.dispute_order.id === action.payload.dispute_order.id);
            if (index !== -1) {
                state.disputes.results[index] = {...action.payload};
            }
        },
    },
});
export const disputeReducer = slice.reducer;
export const disputeActions = slice.actions;


export const fetchDisputesTC = (data: DisputeParamsType): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        DisputeService.fetchDisputes(data).then((res) => {
            dispatch(disputeActions.setDisputes(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

export const setNewDisputeTC = (data: NewDisputeParamsType): AppThunk<Promise<DisputeType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await DisputeService.setNewDispute(data);

            dispatch(disputeActions.setNewDispute(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));

            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };

export const changeDisputeTC = (data: EditDisputeParamsType): AppThunk<Promise<DisputeType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await DisputeService.changeDisputeStatus(data);

            dispatch(disputeActions.setDisputeUpdates(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));

            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };

