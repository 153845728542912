import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {TextFieldCustom} from "../textField/TextField";

type PropsType = {
    formik: any
}

export const AccountNumberFilter: FC<PropsType> = ({formik}) => {
    const {t} = useTranslation();

    return (
        <>
            <TextFieldCustom title={t('filters.account_number')} formik={formik}
                             formikValue={'account_number'}/>
        </>
    )
}