import React, {useEffect} from "react";
import style from "./PayinModal.module.scss";
import {Modal} from "@mui/material";
import {ReactComponent as Cross} from "../../../assets/images/cross.svg";
import {BasicSelect} from "../../../components/basicSelect/BasicSelect";
import * as Yup from "yup";
import {useFormik} from "formik";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../../state/store";
import {modalsActions, PayInPayoutModalType, PayInPayoutParamsType} from "../../../state/reducers/modals-reducer";
import {TextFieldCustom} from "../../../components/textField/TextField";
import {SettingType} from "../../../state/reducers/setting-reducer";
import {NumberTextField} from "../../../components/numberTextField/NumberTextField";
import {numberWithSpaces} from "../../../utils/utils";
import {setPayInOutTransactionTC} from "../../../state/reducers/transactions-reducer";
import {fetchUsersTC} from "../../../state/reducers/users-reducer";
import {UsersFilterType} from "../../../state/models/UsersResponse";
import {fetchDefiniteBalanceTC} from "../../../state/reducers/balance-reducer";
import {UserFilter} from "../../../components/filters/UserFilter";
import {AmountFilter} from "../../../components/filters/AmountFilter";
import {useTranslation} from "react-i18next";
import {CommentFilter} from "../../../components/filters/CommentFilter";


const INITIAL_FORM_STATE = {
    user_id: '',
    amount: null,
    comment: '',
};
export const PayInOutModal = () => {
    const state = useSelector<AppRootStateType, PayInPayoutModalType | null>(state => state.modals.payInOutUser);
    const balanceApi = useSelector<AppRootStateType, number | null>(state => state.balance.state.definiteUserBalance);
    const filters = useSelector<AppRootStateType, UsersFilterType>(state => state.users?.filters);
    const currentPage = useSelector<AppRootStateType, number>(state => state.users?.users?.page);
    const dispatch = useAppDispatch();
    const balance = balanceApi ? balanceApi : 0;
    const {t} = useTranslation();

    const VALIDATION_SCHEMA_PAY_IN = Yup.object({
        user_id: Yup.string().required('Поле обязательно'),
        amount: Yup.number().required('Поле обязательно').positive('Сумма должна быть больше 0')
    })
    const VALIDATION_SCHEMA_PAY_OUT = Yup.object({
        user_id: Yup.string().required('Поле обязательно'),
        amount: Yup.number().required('Поле обязательно').positive('Сумма должна быть больше 0').lessThan(balance + 1, 'Cумма не должна превышать лимит'),
    })
    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(modalsActions.setPayInOutUserModal(null))
    };

    useEffect(() => {
        if (state) {
            dispatch(fetchDefiniteBalanceTC(state?.id))
        }
    }, [state]);

    const formik = useFormik({
        initialValues: INITIAL_FORM_STATE,
        validationSchema: state?.type === 'payout' ? VALIDATION_SCHEMA_PAY_OUT : VALIDATION_SCHEMA_PAY_IN,
        onSubmit: async (values, action) => {
            if (state) {
                const applyObj: PayInPayoutParamsType = {
                    type: state.type,
                    amount: values.amount,
                    user_id: +values.user_id,
                }
                if (values.comment !== '') {
                    applyObj.comment = values.comment;
                }
                const res = await dispatch(setPayInOutTransactionTC(applyObj));

                if (res) {
                    dispatch(fetchUsersTC({
                        ...filters,
                        page: currentPage,
                        page_size: Number(localStorage.getItem('perPageUsers')) || 1
                    }))
                    closeModalHandler()
                } else {
                    // formik.setFieldError('name', 'Пользователь с таким именем уже существуем');
                }
            }
        }
    })
    useEffect(() => {
        formik.setFieldValue('user_id', state?.id)
    }, [state]);

    return (
        <Modal className={style.WrapperModal}
               open={!!state}
               style={{zIndex: '9999999999'}}>
            <form className={style.Container} onSubmit={formik.handleSubmit}>
                <div className={style.HeaderContainer}>
                    <h3>{state?.title}</h3>
                    <button>
                        <Cross onClick={closeModalHandler}/>
                    </button>
                </div>
                <div className={style.FiltersContainer}>
                    <UserFilter formik={formik}/>
                    <div className={style.AmountBlock}>
                        <AmountFilter formik={formik}/>
                        <div className={style.AvailableAmount}>
                            <p className={style.Available}>{t('withdrawModal.availableAmount')}:</p>
                            <p className={style.AvalableBalance} onClick={() => {
                                formik.setFieldValue('amount', state?.balance);
                            }}>{balanceApi && numberWithSpaces(balanceApi)}</p>
                        </div>
                    </div>
                    <CommentFilter formik={formik}/>
                </div>
                <div className={style.BtnsContainer}>
                    <button className={style.CancelBtn} type={'button'} onClick={closeModalHandler}>
                        {t('cancelBtn')}
                    </button>
                    <button
                        type={'submit'}
                        className={`${style.ApplyBtn} ${(!formik.isValid || !formik.dirty) && style.BtnApplyDisabled}`}
                        disabled={!formik.isValid || !formik.dirty}>
                        {state?.actionBtn}
                    </button>
                </div>
            </form>
        </Modal>
    )
}