import style from "./Login.module.scss";
import {TextField} from "@mui/material";
import React from "react";
import {RequestStatusType} from "../../state/reducers/appReducer";

type TextFieldProps = {
    type: 'text' | 'password'
    formik: any
    title: string
    status: RequestStatusType
    label: string
}

export const TextFieldLogin: React.FC<TextFieldProps> = ({type, formik, label, title, status}) => {
    const error = (formik.errors[title] && formik?.touched[title]);

    return (
        <div className={style.FieldWr}>
            <label htmlFor={'login'}>{label}</label>
            <TextField
                {...formik.getFieldProps(title)}
                type={type}
                className={`${style.Input} ${(error || (status === 'failLogin' ||status === 'Locked' )) && style.InputError}`}
                placeholder={title}
            />

            {error ?
                <div className={style.Error}>{formik.errors[title]}</div> : null}
        </div>)
}