import axios from "axios";

export const $api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
})
export const $authApi = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
})
