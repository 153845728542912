import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Checkbox,
    TextField,
} from "@mui/material";
import style from "./MultipleSelect.module.scss";
import {FormikProvider} from "formik";
import {SettingType} from "../../state/reducers/setting-reducer";
import {ReactComponent as CheckBoxImg} from "../../assets/images/Checkbox1.svg";
import {ReactComponent as CheckBoxImgUnChecked} from "../../assets/images/CheckboxUnChecked.svg";
import {useTranslation} from "react-i18next";
import './AutoComplete.scss';

type MultipleSelectType = {
    title: string
    formikValue: string
    items?: SettingType[]
    formik: any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

const MultipleSelect2 = (props: MultipleSelectType) => {
    const {t} = useTranslation();

    const {title, items, formik, formikValue} = props;
    const [open, setOpen] = useState(false)

    const [selectedVal, setSelectedVal] = useState<SettingType[]>(formik.values[formikValue])
    const [selectedName, setSelectedName] = useState<string>('')

    const handleOpen = () => {
        setOpen(!open)
    }

    useEffect(() => {
        setSelectedVal(formik.values[formikValue])
        const va = formik.values[formikValue].map((a: SettingType) => a.name)
        const val2 = formik.values[formikValue]

        setSelectedName(va.join(', '))
        setSelectedVal(val2)

    }, [formik, formikValue]);


    const resetValues = () => {
        formik.setFieldValue(formikValue, [])
        setSelectedName('')
    }

    return (
        <FormikProvider value={formik}>
            {
                items && <div className={style.FilterBox}>
                    <div className={style.TitleContainer}>
                        <p>
                            {title}
                        </p>
                        {selectedVal.length > 0 && <button type={'button'} className={style.ClearBtn} onClick={resetValues}>Очистить</button>}
                    </div>
                    <div className={style.ImitationSelectedBlock} onClick={() => setOpen(!open)}>
                        {selectedVal.length <= 0
                            ? <div className={style.NotSelected} onClick={() => setOpen(!open)}>
                                {t('notChosen')}
                            </div>
                            : <div className={style.NotSelected} onClick={() => setOpen(!open)}>
                                <div className={style.ValuesBlock}>
                                    <p className={style.ParagraphOfSelectedName}>{selectedName}</p>
                                </div>
                            </div>}
                    </div>
                    {open && <div className={style.Cover} onClick={handleOpen}></div>}
                    <div className={`${style.AutoCompleteContainer} ${open && style.AutoCompleteContainerOpen}`}>
                        <Autocomplete
                            multiple
                            id={'formikValue'}
                            onChange={(event, value: SettingType[]) => {
                                console.log(value)
                                if (props.setFieldValue) {
                                    props.setFieldValue(formikValue, value);
                                }
                            }}
                            className={`${style.Autocomplite} ${open && style.AutoCompleteOpen}`}
                            options={items}
                            // clearIcon={<div className={style.ClearBtn} onClick={resetValues}>Очистить</div>}
                            open={open}
                            aria-label={''}
                            value={formik.values[formikValue]}
                            getOptionLabel={(option: SettingType) => option.name}
                            isOptionEqualToValue={(option: any, value: SettingType) => option.value === value.value}
                            renderOption={(props, option: any, {selected}) => {
                                const {key, ...optionProps} = props;
                                console.log(props)

                                return (
                                    <li key={key}  {...optionProps} className={style.MenuItem}>
                                        <div className={style.Wrapper}>
                                            <Checkbox
                                                checked={selected}
                                                checkedIcon={<CheckBoxImg className={style.CheckedIcon}/>}
                                                icon={<CheckBoxImgUnChecked className={style.CheckedIcon}/>}
                                                className={style.CheckBox}
                                            />
                                            {option.name}
                                        </div>
                                    </li>
                                );
                            }}
                            style={{width: 500}}
                            renderInput={(params) => (
                                <TextField {...params} placeholder={'Поиск по имени'} className={style.TextField}/>
                            )}
                        />
                    </div>

                </div>
            }
        </FormikProvider>
    )

}
export default MultipleSelect2;