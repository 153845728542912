import style from "./AddRegisters.module.scss";
import {Modal} from "@mui/material";
import {ReactComponent as Cross} from "../../../assets/images/cross.svg";
import {TextFieldCustom} from "../../../components/textField/TextField";
import {useFormik} from "formik";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import * as Yup from "yup";
import {AppRootStateType} from "../../../state/store";
import {modalsActions} from "../../../state/reducers/modals-reducer";
import {
    instance_StatusesConst,
    NewRegisterParamsType,
} from "../../../state/models/RegisterResponse";
import {DateComponent} from "../../../components/dateComponent/DateComponent";
import {BasicSelect} from "../../../components/basicSelect/BasicSelect";
import {SettingType} from "../../../state/reducers/setting-reducer";
import {formatDateForApi} from "../../../utils/utils";
import {setNewRegisterTC} from "../../../state/reducers/register-reducer";
import {useTranslation} from "react-i18next";
import {UserFilter} from "../../../components/filters/UserFilter";
import {DeviceFilter} from "../../../components/filters/DevicesFilter";
import {AccountFilter} from "../../../components/filters/AccountFilter";
import {BankFilter} from "../../../components/filters/BanksFilter";
import {PaymentMethodFilter} from "../../../components/filters/PaymentMethodFilter";
import {StatusFilter} from "../../../components/filters/StatusFilter";

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string().required('Поле обязательно'),
});


export const AddRegisterByOrders = () => {
    const state = useSelector<AppRootStateType, boolean>(id => id.modals.addRegisterByOrders);
    const orderTypes = useSelector<AppRootStateType, SettingType[]>(state => state.settings.order_types);
    const users = useSelector<AppRootStateType, SettingType[]>(state => state.settings.users);
    const devices = useSelector<AppRootStateType, SettingType[]>(state => state.settings.devices);
    const accounts = useSelector<AppRootStateType, SettingType[]>(state => state.settings.accounts);
    const banks = useSelector<AppRootStateType, SettingType[]>(state => state.settings.banks);
    const methods = useSelector<AppRootStateType, SettingType[]>(state => state.settings.methods);
    const {t} = useTranslation();

    const [dateRange, setDateRange] = useState<any>([null, null]);
    const [startDate, endDate] = dateRange;
    const dispatch = useAppDispatch();
    const INITIAL_FORM_STATE: NewRegisterParamsType = {
        name: '',
        type: '',
        start_date: '',
        end_date: '',
        instance_status: '',
        user_id: null,
        device_id: null,
        account_id: null,
        bank: '',
        order_method: '',
        number: '',
    };


    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(modalsActions.changeStateOfRegisterByOrders(false))
    }

    const formik = useFormik({
        initialValues: INITIAL_FORM_STATE,
        validationSchema: VALIDATION_SCHEMA,
        onSubmit: async (values, action) => {
            const applyObj: NewRegisterParamsType = {}
            if (values.name !== '') {
                applyObj.name = values.name
            }
            if (values.type !== '') {
                applyObj.type = values.type
            }
            if (values.instance_status !== '') {
                applyObj.instance_status = values.instance_status
            }
            if (values.bank !== '') {
                applyObj.bank = values.bank
            }
            if (values.order_method !== '') {
                applyObj.order_method = values.order_method
            }
            if (values.number !== '') {
                applyObj.number = values.number
            }
            if (startDate !== null) {
                applyObj.start_date = formatDateForApi(startDate)
            }
            if (endDate !== null) {
                applyObj.end_date = formatDateForApi(endDate)
            }
            if (values.user_id !== null) {
                applyObj.user_id = values.user_id
            }
            if (values.device_id !== null) {
                applyObj.device_id = values.device_id
            }
            if (values.account_id !== null) {
                applyObj.account_id = values.account_id
            }
            applyObj.type = 'orders'
            const res = await dispatch(setNewRegisterTC(applyObj));
            if (!res) {
                formik.setFieldError('name', 'Реестр с таким названием уже существуем');
            } else {
                closeModalHandler()
            }

        }

    })


    return (
        <Modal className={style.WrapperModal}
               open={state}
               style={{zIndex: '9999999999'}}>
            <form className={style.Container} onSubmit={formik.handleSubmit}>
                <div className={style.HeaderContainer}>
                    <h3>{t('registers.ordersRegisterTitle')}</h3>
                    <button>
                        <Cross onClick={closeModalHandler}/>
                    </button>
                </div>
                <div className={style.FiltersContainer}>
                    <TextFieldCustom title={t('registers.registerName')} formik={formik} formikValue={'name'}/>
                    <DateComponent setDateRange={setDateRange} endDate={endDate} startDate={startDate}/>
                    {orderTypes && <BasicSelect label={t('filters.exchange_direction')} title={'type'} formik={formik}
                                                items={orderTypes}/>}
                    <BasicSelect label={t('registers.payStatus')} title={'instance_status'} formik={formik}
                                 items={instance_StatusesConst}/>
                    <UserFilter formik={formik} type={'extended'}/>
                    <DeviceFilter formik={formik} type={'extended'}/>
                    <AccountFilter formik={formik} type={'extended'}/>
                    <BankFilter formik={formik} type={'extended'}/>
                    {methods &&
                        <BasicSelect label={t('registers.payMethod')} title={'order_method'} formik={formik}
                                     items={methods}/>}
                    <TextFieldCustom title={t('registers.requisite')} formik={formik} formikValue={'number'}/>
                </div>
                <div className={style.BtnsContainer}>
                    <button className={style.CancelBtn} type={'button'} onClick={closeModalHandler}>
                        {t('cancelBtn')}
                    </button>
                    <button
                        type={'submit'}
                        className={`${style.ApplyBtn} ${(!formik.isValid || !formik.dirty) && style.BtnApplyDisabled}`}
                        disabled={!formik.isValid || !formik.dirty}>
                        {t('saveBtn')}
                    </button>
                </div>
            </form>
        </Modal>
    )
}