export type DeviceParamsType = {
    status?: string
    user_id?: string
    name?: string
    payin_token?: string
    payout_token?: string
    telegram_id?: string
    page?: number
    page_size?: number
}

export enum deviceStatusesEnum {
    enabled = 'enabled',
    disabled = 'disabled',
    archived = 'archived',
}

export type EditDeviceParamsType = {
    status?: deviceStatusesEnum
    name?: string
    payin_token?: string
    payout_token?: string
    telegram_id?: string
    tx_min?: number | null
    tx_max?: number | null
    daily_limit?: number | null
    month_limit?: number | null
}
export type AddDeviceParamsType = {
    name?: string
    payin_token?: string
    payout_token?: string
    telegram_id?: string
    tx_min?: number | null
    tx_max?: number | null
    daily_limit?: number | null
    month_limit?: number | null
}
export const deviceStatusesState = [
    {
        name: 'Не выбрано',
        value: 'Не выбрано',
    }, {
        name: deviceStatusesEnum.enabled,
        value: deviceStatusesEnum.enabled,
    }, {
        name: deviceStatusesEnum.disabled,
        value: deviceStatusesEnum.disabled,
    }, {
        name: deviceStatusesEnum.archived,
        value: deviceStatusesEnum.archived,
    },
]
export type DeviceResponseType = {
    count: number
    page: number
    page_size: number
    total_pages: number
    results: DeviceType[]
}
export type DeviceType = {
    id: number
    created_at: string
    name: string
    status: deviceStatusesEnum
    username: string
    accounts_count: number
    payment_details_count: number
    payin_app: {
        status: string
        token: string
        last_ping: string
    },
    payout_app: {
        "status": string
        "token": string
        "last_order": string
    },
    payout_telegram: {
        "status": string
        "telegram_id": string
        "last_order": string
    }
    tx_min: number
    tx_max: number
    daily_limit: number
    month_limit: number
};

export type DeviceColumnSizeType = {
    id: number
    name: number
    created_at: number
    payin_app: number
    payout_app: number
    accounts_count: number
    payment_details_count: number
    status: number
    entity: number
}

export const DeviceSettingsState = {
    id: true,
    name: true,
    created_at: true,
    payin_app: true,
    payout_app: true,
    accounts_count: true,
    payment_details_count: true,
    status: true,
}
export type DeviceSettingsType = {
    id?: boolean
    name?: boolean
    created_at?: boolean
    status?: boolean
    payin_app?: boolean
    payout_app?: boolean
    accounts_count?: boolean
    payment_details_count?: boolean
    entity?: boolean
}
export type NamesDeviceType = 'id'
    | 'name'
    | 'created_at'
    | 'payin_app'
    | 'payout_app'
    | 'accounts_count'
    | 'payment_details_count'
    | 'status'
    | 'entity'

export const NamesDeviceOrder = ['id', 'name', 'created_at', 'payin_app', 'payout_app', 'accounts_count', 'payment_details_count', 'status', 'entity']
