export enum banksEnum {
    sberbank = 'sberbank',
    tinkoff = 'tinkoff',
    ozon = 'ozon',
}

export const banksEnumRu = {
    [banksEnum.sberbank]: 'Сбербанк',
    [banksEnum.tinkoff]: 'Тинькофф',
    [banksEnum.ozon]: 'Ozon',
}

export enum methodsEnum {
    card = 'card',
    sbp = 'sbp',
    accountNumber = 'accountNumber',
}

export const methodsEnumRu = {
    [methodsEnum.card]: 'Карта',
    [methodsEnum.sbp]: 'СБП',
    [methodsEnum.accountNumber]: 'Счёт',
}

export enum courseSourceEnum {
    garantex = 'garantex',
    garantex_1 = 'garantex_1',
    moex = 'moex',
}

export const courseSourceEnumRu = {
    [courseSourceEnum.garantex]: 'Garantex',
    [courseSourceEnum.garantex_1]: 'Garantex+1',
    [courseSourceEnum.moex]: 'Мос. биржа',
}

export enum registersTypeEnum {
    orders = 'orders',
    transactions = 'transactions',
}

export const registersTypeEnumRu = {
    [registersTypeEnum.orders]: 'По ордерам',
    [registersTypeEnum.transactions]: 'По финансам',
}


export enum localStorageAutoUpdate {
    transactions = 'TransactionsAutoUpdate',
    requisites = 'RequisitesAutoUpdate',
    users = 'UsersAutoUpdate',
    registers = 'RegistersAutoUpdate',
    orders = 'OrdersAutoUpdate',
    statistics = 'StatisticsAutoUpdate',
    devices = 'DevicesAutoUpdate',
    accounts = 'AccountsAutoUpdate',
    disputes = 'DisputesAutoUpdate',
}
export enum localStorageAutoUpdateTime {
    transactions = 'TransactionsAutoUpdateTime',
    requisites = 'RequisitesAutoUpdateTime',
    users = 'UsersAutoUpdateTime',
    registers = 'RegistersAutoUpdateTime',
    orders = 'OrdersAutoUpdateTime',
    statistics = 'StatisticsAutoUpdateTime',
    devices = 'DevicesAutoUpdateTime',
    accounts = 'AccountsAutoUpdateTime',
    disputes = 'DisputesAutoUpdateTime',
}