import {BasicSelect} from "../basicSelect/BasicSelect";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {SettingType} from "../../state/reducers/setting-reducer";
import {useTranslation} from "react-i18next";
import MultipleSelect2 from "../multipleSelect/MultipleSelect2";

type PropsType = {
    formik: any
    type?: 'extended'
    typeOfSelect?: 'multiple' | 'basic'
}

export const BankFilter: FC<PropsType> = ({formik, type, typeOfSelect}) => {
    const {t} = useTranslation();
    const banks = useSelector<AppRootStateType, SettingType[]>(state => state.settings.banks);
    const defaultVal = t('notChosen');

    const defaulFiltertObj = {
        value: defaultVal, name: defaultVal
    }
    const items = banks && type !== 'extended' ? [defaulFiltertObj, ...banks] : banks
    return (
        <>
            {banks && (typeOfSelect !== 'multiple'
                    ? <BasicSelect label={t("filters.bank")} title={'bank'}
                                   formik={formik} items={items}/>
                    : <MultipleSelect2 setFieldValue={formik.setFieldValue} title={t("filters.bank")}
                                       formikValue={'bank'}
                                       formik={formik} items={banks}/>
            )}
        </>
    )
}