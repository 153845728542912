import React, {Suspense} from 'react';
import './App.css';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {ProtectedRoute} from "./protectedRoute/ProtectedRoute";
import DefaultLayout from "./layout/DefaultLayout";
import {LoginPage} from "./pages/login/Login";
import './assets/styles/reset.css';
import "react-datepicker/dist/react-datepicker.css";
import {useSelector} from "react-redux";
import {AppRootStateType} from "./state/store";
import {CircularProgress} from "@mui/material";
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.css'


function App() {

    const isInitialized = useSelector<AppRootStateType, boolean>(state => state.app.isInitialized)

    return (
        <BrowserRouter>
            <Suspense>
                {
                    isInitialized
                        ? <Routes>
                            <Route path="/" element={<Navigate to="/login" replace/>}/>
                            <Route path={'/login'} element={<LoginPage/>}/>
                            <Route path="*" element={
                                <ProtectedRoute>
                                    <DefaultLayout/>
                                </ProtectedRoute>
                            }/>
                        </Routes> : <CircularProgress/>
                }

            </Suspense>
        </BrowserRouter>
    );
}

export default App;
