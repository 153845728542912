import style from './Login.module.scss';
import * as Yup from "yup";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {useFormik} from "formik";
import {AppRootStateType} from '../../state/store';
import {RequestStatusType} from '../../state/reducers/appReducer';
import {TextFieldLogin} from "./TextFieldLogin";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {loginTC} from "../../state/reducers/auth-reducer";


const FORM_VALIDATION = Yup.object().shape({
    username: Yup.string().trim()
        .required('Required'),
    password: Yup.string()
        .required('Required').min(4).trim(),
})

export const LoginPage = () => {
    const dispatch = useAppDispatch();
    const isLoggedIn = useSelector<AppRootStateType, boolean>(state => state.auth.isLoggedIn);
    const status = useSelector<AppRootStateType, RequestStatusType>(state => state.app.status);
    const role = useSelector<AppRootStateType, string | null>(state => state.auth.role);

    let navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            if (role === 'support') {
                return navigate("/orders");
            }
            if (role === 'user') {
                return navigate("/statistics");
            } else {
                return navigate("/users");
            }

        }
    }, [isLoggedIn]);

    const [title, setTitle] = useState(' Введите данные, полученные у администратора');

    const myRef = useRef<HTMLParagraphElement>(null);

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: FORM_VALIDATION,
        enableReinitialize: true,
        onSubmit: async (values, action) => {
            const applyObj = {
                username: values.username.trim(),
                password: values.password.trim(),
            }
            const res = await dispatch(loginTC(applyObj))
            if (!res) {
                formik.setErrors({
                    username: '',
                    password: '',
                })

            }
        }
    })
    const disabledState = !formik.isValid || !formik.dirty;
    useEffect(() => {
        if (status === 'Locked') {
            setTitle('Пользователь заблокирован')
        } else if (status === 'failLogin') {
            setTitle('Вы ввели неверный логин или пароль')
        }
    }, [status]);
    return (

        <div className={style.Wrapper}>
            <h1>Вход</h1>
            <p ref={myRef} className={`${(status === 'failLogin' || status === 'Locked') && style.ErrorDesc}`}>
                {title}
            </p>
            <form onSubmit={formik.handleSubmit} className={style.Form}>
                <TextFieldLogin type={'text'} formik={formik} title={'username'} label={'Login'}
                                status={status}/>
                <TextFieldLogin type={'password'} formik={formik} title={'password'} label={'Password'}
                                status={status}/>
                <button
                    className={`${style.BtnSubmit} ${disabledState && style.BtnSubmitDisabled}`}
                    disabled={disabledState}
                    type={'submit'}
                >
                    Войти
                </button>
            </form>
        </div>
    )
}