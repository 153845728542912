import {AxiosResponse} from "axios";
import {$api} from "../http";
import {getToken} from "../../utils/utils";
import {TransactionParamsType, TransactionResponseType} from "../models/TransactionResponse";
import {PayInPayoutParamsType} from "../reducers/modals-reducer";


export const TransactionService = {
    fetchTransactions(params: TransactionParamsType): Promise<AxiosResponse<TransactionResponseType>> {
        return $api.get('/transactions', {
            params: params,
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    setUserUpdates(params: PayInPayoutParamsType): Promise<AxiosResponse<TransactionResponseType>> {
        return $api.post('/transactions', params, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }

}

