import style from "./TextField.module.scss";
import React from "react";
import {TextField} from "@mui/material";
import {FormikProvider} from "formik";

interface TextFieldI {
    title: string
    formik: any
    formikValue: string
    placeholder?: string
    disabled?: boolean
}

export const TextFieldCustom: React.FC<TextFieldI> = ({title, disabled, formik, placeholder, formikValue}) => {
    const error = (formik?.errors[formikValue] && formik?.touched[formikValue]);

    return (
        <FormikProvider value={formik}>
            <div className={style.FilterBox}>
                <p>
                    {title}
                </p>
                <TextField {...formik.getFieldProps(formikValue)}
                    // value={formik[formikValue]}
                           disabled={disabled}
                           onBlur={formik.handleBlur}
                           placeholder={placeholder ? placeholder : title}
                           className={`${style.TextField} ${error && style.TextFieldError}`}/>
                {
                    error && <p className={style.Error}>{formik?.errors[formikValue]}</p>
                }
            </div>

        </FormikProvider>

    )
}