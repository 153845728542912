import style from "./RequisiteModal.module.scss";
import {Modal} from "@mui/material";
import {ReactComponent as Cross} from "../../../assets/images/cross.svg";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../../state/store";
import {AccountSettingType, SettingType} from "../../../state/reducers/setting-reducer";
import {NumberTextField} from "../../../components/numberTextField/NumberTextField";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import * as Yup from "yup";
import {modalsActions} from "../../../state/reducers/modals-reducer";
import {RequisitType, requsiteStatusModal} from "../../../state/models/RequisitesResponse";
import {editRequisiteTC, getDefiniteRequisiteTC} from "../../../state/reducers/requisites-reducer";
import {methodsEnum} from "../../../enums/enums";
import {useTranslation} from "react-i18next";
import {NameFilter} from "../../../components/filters/NameFilter";
import {AccountFilter} from "../../../components/filters/AccountFilter";
import {BankFilter} from "../../../components/filters/BanksFilter";
import {PaymentMethodFilter} from "../../../components/filters/PaymentMethodFilter";
import {DailyLimitFilter} from "../../../components/filters/DayliLimitFilter";
import {MonthLimitFilter} from "../../../components/filters/MonthLimitFilter";
import {RequisiteDataFilter} from "../../../components/filters/RequisiteDataFilter";
import {CardholderFilter} from "../../../components/filters/CardHolderFilter";
import {AccountNumberFilter} from "../../../components/filters/AccountNumberFilter";
import {StatusFilter} from "../../../components/filters/StatusFilter";


const VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Поле обязательно'),
    method: Yup.string().required('Поле обязательно'),
    number: Yup.string().required('Поле обязательно'),
    status: Yup.string().required('Поле обязательно'),
    cardholder: Yup.string(),
    account_number: Yup.string().notRequired().when('method', {
        is: 'sbp',
        then: (schema) => schema.required("Поле обязательно"),
    }),
    account_id: Yup.number().required('Поле обязательно').min(0, 'Минимальное значение 0'),
    daily_limit: Yup.number().required('Поле обязательно').min(0, 'Минимальное значение 0'),
    month_limit: Yup.number().required('Поле обязательно').min(0, 'Минимальное значение 0'),
    tx_min: Yup.number().required('Поле обязательно').min(0, 'Минимальное значение 0'),
    tx_max: Yup.number().required('Поле обязательно').min(0, 'Минимальное значение 0'),
});

export const EditRequisite = () => {
    const dispatch = useAppDispatch();
    const id = useSelector<AppRootStateType, number | null>(state => state.modals.editRequisite);
    const requisite = useSelector<AppRootStateType, RequisitType | null>(state => state.requisites.definiteRequisite);
    const accounts = useSelector<AppRootStateType, AccountSettingType[]>(state => state.settings.accounts);
    const methods = useSelector<AppRootStateType, SettingType[]>(state => state.settings.methods);
    const banks = useSelector<AppRootStateType, SettingType[]>(state => state.settings.banks);


    useEffect(() => {
        if (id) {
            dispatch(getDefiniteRequisiteTC(id))
        }
    }, [id]);

    const INITIAL_FORM_STATE = {
        method: requisite?.method,
        name: requisite?.name,
        number: requisite?.number,
        cardholder: requisite?.cardholder,
        account_number: requisite?.account_number,
        account_id: requisite?.account_id,
        daily_limit: requisite?.daily_limit,
        month_limit: requisite?.month_limit,
        tx_min: requisite?.tx_min,
        tx_max: requisite?.tx_max,
        status: requisite?.status,
        bank: requisite?.bank
    };
    const closeModalHandler = () => {
        formik.resetForm()
        dispatch(modalsActions.changeStateOfRequisiteEditModal(null))
    }
    const formik = useFormik({
        initialValues: INITIAL_FORM_STATE,
        validationSchema: VALIDATION_SCHEMA,
        onSubmit: async (values, action) => {
            if (id) {
                const res = await dispatch(editRequisiteTC(id, values));
                closeModalHandler()
                if (!res) {
                    formik.setFieldError('username', 'Реквизит с таким названием уже существуем');
                }
            }
        }
    })

    useEffect(() => {
        formik.resetForm({
            values: {
                method: requisite?.method,
                name: requisite?.name,
                number: requisite?.number,
                cardholder: requisite?.cardholder,
                account_number: requisite?.account_number,
                account_id: requisite?.account_id,
                daily_limit: requisite?.daily_limit,
                month_limit: requisite?.month_limit,
                tx_min: requisite?.tx_min,
                tx_max: requisite?.tx_max,
                status: requisite?.status,
                bank: requisite?.bank
            }
        })
        console.log(requisite?.bank)
    }, [requisite]);

    const [selectedBank, setSelectedBank] = useState<string>();

    useEffect(() => {
        if (accounts) {
            const account = accounts.filter(a => a.value === formik.values.account_id);
            setSelectedBank(account[0]?.bank)
            formik.setFieldValue('bank', account[0]?.bank)
        }
    }, [formik.values.account_id]);
    const {t} = useTranslation();

    return (
        <Modal className={style.WrapperModal}
               open={!!id}
               style={{zIndex: '9999999999'}}>
            <form className={style.Container} onSubmit={formik.handleSubmit}>
                <div className={style.HeaderContainer}>
                    <h3>{t('editPaymentDetail.title')}</h3>
                    <button type={"button"}>
                        <Cross onClick={closeModalHandler}/>
                    </button>
                </div>
                <div className={style.FiltersContainer}>
                    <NameFilter formik={formik}/>
                    <AccountFilter formik={formik} type={'extended'}/>
                    <BankFilter formik={formik} type={'extended'}/>
                    <PaymentMethodFilter formik={formik} type={'extended'}/>
                    <div className={style.RewardBlock}>
                        <DailyLimitFilter formik={formik}/>
                        <MonthLimitFilter formik={formik}/>
                    </div>
                    <div className={style.RestrictionBlock}>
                        <p>{t('addDevice.limits')}</p>
                        <div>
                            <div>
                                <p>{t('addDevice.from')}</p>
                                <NumberTextField formikValue={'tx_min'} formik={formik}
                                                 title={''}
                                                 error={formik.errors.tx_min}/>
                            </div>
                            <div>
                                <p>{t('addDevice.to')}</p>
                                <NumberTextField formikValue={'tx_max'} formik={formik}
                                                 title={''}
                                                 error={formik.errors.tx_max}/>
                            </div>
                        </div>
                    </div>
                    <RequisiteDataFilter formik={formik}/>
                    <CardholderFilter formik={formik}/>
                    {formik.values.method === methodsEnum.sbp &&
                        <AccountNumberFilter formik={formik}/>
                    }
                    <StatusFilter formik={formik} items={requsiteStatusModal} type={'extended'}/>
                </div>
                <div className={style.BtnsContainer}>
                    <button className={style.CancelBtn} type={'button'} onClick={closeModalHandler}>
                        {t('cancelBtn')}
                    </button>
                    <button
                        type={'submit'}
                        className={`${style.ApplyBtn} ${(!formik.isValid || !formik.dirty) && style.BtnApplyDisabled}`}
                        disabled={!formik.isValid || !formik.dirty}>
                        {t('addBtn')}
                    </button>
                </div>
            </form>
        </Modal>
    )
}