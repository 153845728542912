import {AxiosResponse} from "axios";
import {$api} from "../http";
import {getToken} from "../../utils/utils";
import {
    NewRegisterParamsType,
    RegisterParamsType,
    RegisterResponseType,
    RegisterType
} from "../models/RegisterResponse";


export const RegisterService = {
    fetchRegisters(params: RegisterParamsType): Promise<AxiosResponse<RegisterResponseType>> {
        return $api.get('/dumps', {
            params: params,
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    setNewRegister(params: NewRegisterParamsType): Promise<AxiosResponse<RegisterType>> {
        return $api.post('/dumps', params, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }

}

