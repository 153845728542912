import style from "./TextFieldMultuline.module.scss";
import React from "react";
import {TextField} from "@mui/material";
import {FormikProvider} from "formik";
import {useTranslation} from "react-i18next";

interface TextFieldI {
    title: string
    formik: any
    formikValue: string
}

export const TextFieldMultiLine: React.FC<TextFieldI> = ({title, formik, formikValue}) => {
    const {t} = useTranslation();


    return (
        <FormikProvider value={formik}>
            <div className={style.FilterBox}>
                <p>
                    {title}
                </p>
                <TextField {...formik.getFieldProps(formikValue)}
                           value={formik.values.merchant_id}
                           multiline={true}
                           minRows={2}
                           maxRows={3}
                           placeholder={t('addUserModal.merchatIdPlaceholder')} className={style.TextField}/>
            </div>
        </FormikProvider>

    )
}