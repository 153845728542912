import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../../utils/error-utils";
import {StatisticsService} from "../services/StatisticsService";
import {
    StatisticSettingBooleanType,
    StatisticSettingsState,
    StatisticsParamsType,
    StatisticsResponseType
} from "../models/StatisticsResponse";
import {StatisticsFiltersForSelectsType} from "../../pages/statistics/statisticsFilter/StatisticsFilters";

type initialStateType = {
    state: StatisticsResponseType,
    filters: StatisticsParamsType
    filtersForSelect: StatisticsFiltersForSelectsType
    settings: StatisticSettingBooleanType
}
const initialState: initialStateType = {
    state: {
        count: 1,
        total_pages: 1,
        page: 1,
        page_size: 1,
        results: [],
        total: [],
    },
    filters: JSON.parse(localStorage.getItem('statisticFilters') || JSON.stringify({})),
    filtersForSelect: JSON.parse(localStorage.getItem('statisticFiltersForSelect') || JSON.stringify({})),
    settings: JSON.parse(localStorage.getItem('statisticSettings') || JSON.stringify(StatisticSettingsState)),
}

const slice = createSlice({
    name: "statistics",
    initialState: initialState,
    reducers: {
        setStatistics: (state: initialStateType, action: PayloadAction<StatisticsResponseType>) => {
            state.state = action.payload
        },
        setStatisticsSettings: (state, action: PayloadAction<StatisticSettingBooleanType>) => {
            state.settings = action.payload
            localStorage.setItem('statisticSettings', JSON.stringify(action.payload));
        },
        setStatisticsFilters: (state, action: PayloadAction<StatisticsParamsType>) => {
            state.filters = action.payload
            localStorage.setItem('statisticFilters', JSON.stringify(action.payload));
        },
        setStatisticsFiltersForSelect: (state, action: PayloadAction<StatisticsFiltersForSelectsType>) => {
            state.filtersForSelect = action.payload
            localStorage.setItem('statisticFiltersForSelect', JSON.stringify(action.payload));
        },
    },
});
export const statisticsReducer = slice.reducer;
export const statisticsActions = slice.actions;

export const fetchStatisticsTC = (data: StatisticsParamsType): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        StatisticsService.fetchStatistics(data).then((res) => {
            dispatch(statisticsActions.setStatistics(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

