import {AxiosResponse} from "axios";
import {$api} from "../http";
import {getToken} from "../../utils/utils";
import {
    DisputeParamsType,
    DisputeResponseType,
    DisputeType,
    EditDisputeParamsType,
    NewDisputeParamsType
} from "../models/DisputeResponse";


export const DisputeService = {
    fetchDisputes(params: DisputeParamsType): Promise<AxiosResponse<DisputeResponseType>> {
        return $api.get('/disputes', {
            params: params,
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    setNewDispute(params: NewDisputeParamsType): Promise<AxiosResponse<DisputeType>> {
        return $api.post('/disputes', params, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    changeDisputeStatus(params: EditDisputeParamsType): Promise<AxiosResponse<DisputeType>> {
        return $api.put(`/disputes/${params.dispute_id}`, params, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },

}

