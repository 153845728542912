import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../../utils/error-utils";
import {
    NewRegisterParamsType,
    RegisterParamsType,
    RegisterResponseType,
    RegisterSettingsState,
    RegisterSettingsType, RegisterType
} from "../models/RegisterResponse";
import {RegisterService} from "../services/RegisterService";
import {showSuccessToast} from "../../utils/utils";
import {AxiosError} from "axios";
import {RegistersfiltersForSelectType} from "../../pages/registers/registersFilters/Registersfilters";


type  InitialStateType = {
    filters: RegisterParamsType
    filtersForSelects: RegistersfiltersForSelectType
    registers: RegisterResponseType
    settings: RegisterSettingsType
}
const initialState: InitialStateType = {
    filters: JSON.parse(localStorage.getItem('registerFilters') || JSON.stringify({})),
    filtersForSelects: JSON.parse(localStorage.getItem('registerFiltersForSelects') || JSON.stringify({})),
    settings: JSON.parse(localStorage.getItem('registerSettings') || JSON.stringify(RegisterSettingsState)),
    registers: {
        count: 1,
        page: 1,
        page_size: 1,
        results: [],
        total_pages: 1
    }
}

const slice = createSlice({
    name: "registers",
    initialState: initialState,
    reducers: {
        setRegisters: (state, action: PayloadAction<RegisterResponseType>) => {
            state.registers = action.payload
        },
        setRegisterSettings: (state, action: PayloadAction<RegisterSettingsType>) => {
            state.settings = action.payload
            localStorage.setItem('registerSettings', JSON.stringify(action.payload));
        },
        setRegisterFilters: (state, action: PayloadAction<RegisterParamsType>) => {
            state.filters = action.payload
            localStorage.setItem('registerFilters', JSON.stringify(action.payload));
        },
        setRegisterFiltersForSelect: (state, action: PayloadAction<RegistersfiltersForSelectType>) => {
            state.filtersForSelects = action.payload
            localStorage.setItem('registerFiltersForSelects', JSON.stringify(action.payload));
        },
        setNewRegister: (state, action: PayloadAction<RegisterType>) => {
            state.registers.results.unshift(action.payload)
        }
    },
});
export const registerReducer = slice.reducer;
export const registerActions = slice.actions;


export const fetchRegistersTC = (data: RegisterParamsType): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        RegisterService.fetchRegisters(data).then((res) => {
            dispatch(registerActions.setRegisters(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

export const setNewRegisterTC = (params: NewRegisterParamsType): AppThunk<Promise<RegisterType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await RegisterService.setNewRegister(params);
            dispatch(registerActions.setNewRegister(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            showSuccessToast('Действие выполнено успешно');
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };


