import {banksEnum} from "../../enums/enums";
import {SettingType} from "../reducers/setting-reducer";

export type AccountParamsType = {
    bank?: SettingType[]
    device_id?: string
    name?: string
    status?: string
    user_id?: string
    page?: number
    page_size?: number
}
export type AccountResponseType = {
    count: number
    total_pages: number
    page: number
    page_size: number
    results: AccountType[]
}
export type NewAccountParamsType = {
    name?: string
    bank?: string
    device_id?: number | null
    month_limit?: number | null
    day_limit?: number | null
}
export type EditAccountParamsType = {
    name?: string
    bank?: string
    device_id?: number
    status?: string
    day_limit?: null | number
    month_limit?: null | number
}
export type AccountType = {
    id: number
    created_at: string
    name: string
    bank: banksEnum
    status: string
    device_name: string
    device_id: number
    username: string
    payment_details: number
    day_limit: null | number
    month_limit: null | number
    subRows?: AccountType[]
}
export type AccountColumnSizesType = {
    id: number
    created_at: number
    name: number
    bank: number
    status: number
    device_name: number
    device_id: number
    username: number
    payment_details: number
    day_limit: number
    month_limit: number
    entity: number
}

export enum accountStatusesEnum {
    enabled = 'enabled',
    disabled = 'disabled',
    archived = 'archived',
}

export const accountStatuses = [{
    name: accountStatusesEnum.enabled,
    value: accountStatusesEnum.enabled,
}, {
    name: accountStatusesEnum.archived,
    value: accountStatusesEnum.archived,
}, {
    name: accountStatusesEnum.disabled,
    value: accountStatusesEnum.disabled,
}
]
export type AccountHeadType = {
    id?: number
    created_at?: string
    name?: string
    bank?: string
    status?: string
    device_name?: string
    username?: string
    payment_details?: number
}
export const AccountSettingsState = {
    id: true,
    created_at: true,
    name: true,
    bank: true,
    status: true,
    device_name: true,
    username: true,
    payment_details: true
}
export type AccountSettingsType = {
    id?: boolean
    created_at?: boolean
    name?: boolean
    bank?: boolean
    status?: boolean
    device_name?: boolean
    username?: boolean
    payment_details?: boolean
    entity?: boolean
}
export type NamesAccountType =
    'id'
    | 'created_at'
    | 'name'
    | 'bank'
    | 'status'
    | 'device_name'
    | 'username'
    | 'payment_details'
    | 'entity'