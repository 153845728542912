import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../../utils/error-utils";
import {
    TransactionParamsType,
    TransactionResponseType,
    TransactionSettingsState,
    TransactionSettingsType
} from "../models/TransactionResponse";
import {TransactionService} from "../services/TransactionsService";
import {PayInPayoutParamsType} from "./modals-reducer";
import {showSuccessToast} from "../../utils/utils";
import {AxiosError} from "axios";
import {fetchBalanceTC} from "./balance-reducer";
import {TransactionsFiltersForSelectsType} from "../../pages/transactions/transactionsFilters/Transactionsfilters";

type  InitialStateType = {
    filters: TransactionParamsType
    transactions: TransactionResponseType
    filtersForSelects: TransactionsFiltersForSelectsType
    settings: TransactionSettingsType
}
const initialState: InitialStateType = {
    filters: JSON.parse(localStorage.getItem('transactionFilters') || JSON.stringify({})),
    filtersForSelects: JSON.parse(localStorage.getItem('transactionFiltersForSlects') || JSON.stringify({})),
    settings: JSON.parse(localStorage.getItem('transactionSettings') || JSON.stringify(TransactionSettingsState)),
    transactions: {
        count: 1,
        page: 1,
        page_size: 1,
        results: [],
        total_pages: 1
    }
}

const slice = createSlice({
    name: "transactions",
    initialState: initialState,
    reducers: {
        setTransactions: (state, action: PayloadAction<TransactionResponseType>) => {
            state.transactions = action.payload
        },
        setTransactionSettings: (state, action: PayloadAction<TransactionSettingsType>) => {
            state.settings = action.payload
            localStorage.setItem('transactionSettings', JSON.stringify(action.payload));
        },
        setTransactionFilters: (state, action: PayloadAction<TransactionParamsType>) => {
            state.filters = action.payload
            localStorage.setItem('transactionFilters', JSON.stringify(action.payload));
        },
        setTransactionFiltersForSelect: (state, action: PayloadAction<TransactionsFiltersForSelectsType>) => {
            state.filtersForSelects = action.payload
            localStorage.setItem('transactionFiltersForSlects', JSON.stringify(action.payload));
        },
    },
});
export const transactionReducer = slice.reducer;
export const transactionActions = slice.actions;


export const fetchTransactionsTC = (data: TransactionParamsType): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        TransactionService.fetchTransactions(data).then((res) => {
            dispatch(transactionActions.setTransactions(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

export const setPayInOutTransactionTC = (data: PayInPayoutParamsType): AppThunk<Promise<TransactionResponseType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await TransactionService.setUserUpdates(data);
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            showSuccessToast('Действие выполнено успешно');
            dispatch(fetchBalanceTC());
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };

