import {courseSourceEnum, methodsEnum} from "../../enums/enums";

export type OrderParamsType = {
    end_date?: string
    start_date?: string
    device_id?: string
    page?: number
    page_size?: number
    user_id?: string
    status?: string
    method?: string
    type?: string
    account_id?: string
    number?: string
    bank?: string
    payment_detail?: string
    order_id?: string
    processing_id?: string
}

export enum OrderStatuses {
    decline = 'decline',
    error = 'error',
    accept = 'accept',
    pending = 'pending',
    processing = 'processing',
    dispute = 'dispute',
    created = 'created',
}

export enum orderTypes {
    payout = 'payout',
    order = 'order',
}

export const orderTypesRu = {
    [orderTypes.payout]: 'Выплата',
    [orderTypes.order]: 'Покупка',
}

export const ordersConstTypesState = [
    {
        name: 'Не выбрано',
        value: 'Не выбрано',
    }, {
        name: 'Выплата',
        value: orderTypes.payout,
    }, {
        name: 'Покупка',
        value: orderTypes.order,
    }
]
export const ordersConstStatuses = [
    {
        name: OrderStatuses.decline,
        value: OrderStatuses.decline,
    }, {
        name: OrderStatuses.accept,
        value: OrderStatuses.accept,
    }, {
        name: OrderStatuses.processing,
        value: OrderStatuses.processing,
    }, {
        name: OrderStatuses.pending,
        value: OrderStatuses.pending,
    }, {
        name: OrderStatuses.dispute,
        value: OrderStatuses.dispute,
    }, {
        name: OrderStatuses.created,
        value: OrderStatuses.created,
    }, {
        name: OrderStatuses.error,
        value: OrderStatuses.error,
    },
]
export const orderStatusesForModal = [{
    name: OrderStatuses.decline,
    value: OrderStatuses.decline,
}, {
    name: OrderStatuses.accept,
    value: OrderStatuses.accept,
}, {
    name: OrderStatuses.error,
    value: OrderStatuses.error,
},]
export type OrderResponseType = {
    count: number
    total_pages: number
    page: number
    page_size: number
    results: OrderType[]
    total: OrderType[]
}
export type NewOrderParamsType = {
    id: string
    status: string
}
export type OrderType = {
    id: string
    created_at: string
    finalized_at: string
    usdt_amount: number
    rub_amount: number
    type: orderTypes
    exchange_rate: number
    status: string
    method: methodsEnum
    bank: string
    external_id: string
    number: number
    rate_source: courseSourceEnum
    reward: number
    reward_crossbank: number
    reward_total: number
    comment: string
    account_name: string
    disputes?: OrderType[]
    device_name: string
    device_token: number
    total_usdt_amount: number
}

export const OrderSettingsState = {
    created_at: true,
    finalized_at: true,
    id: true,
    external_id: true,
    type: true,
    account_name: true,
    bank: true,
    device_name: true,
    device_token: true,
    method: true,
    number: true,
    rub_amount: true,
    price: true,
    rate_source: true,
    usdt_amount: true,
    reward_total: true,
    reward_crossbank: true,
    reward: true,
    total_usdt_amount: true,
    comment: true,
    status: true,
}
export type OrderSettingsType = {
    created_at?: boolean
    finalized_at?: boolean
    id?: boolean
    external_id?: boolean
    type?: boolean
    account_name?: boolean
    bank?: boolean
    device_name?: boolean
    device_token?: boolean
    method?: boolean
    number?: boolean
    rub_amount?: boolean
    price?: boolean
    rate_source?: boolean
    usdt_amount?: boolean
    reward_total?: boolean
    reward_crossbank?: boolean
    reward?: boolean
    total_usdt_amount?: boolean
    comment?: boolean
    status?: boolean
    entity?: boolean
}
export type NamesOrderType = 'created_at'
    | 'finalized_at'
    | 'id'
    | 'external_id'
    | 'type'
    | 'account_name'
    | 'bank'
    | 'device_name'
    | 'device_token'
    | 'method'
    | 'number'
    | 'rub_amount'
    | 'price'
    | 'rate_source'
    | 'usdt_amount'
    | 'reward_total'
    | 'reward_crossbank'
    | 'reward'
    | 'total_usdt_amount'
    | 'comment'
    | 'status'
    | 'entity'


export const ordersColumnOrder = [
    'created_at', 'finalized_at', 'id', 'external_id', 'type', 'account_name', 'bank', 'device_name', 'device_token', 'method', 'number', 'rub_amount', 'price', 'rate_source', 'usdt_amount', 'reward_total', 'reward_crossbank', 'reward', 'total_usdt_amount', 'comment', 'status', 'entity'
]

export type OrderColumnSizesType = {
    created_at: number
    finalized_at: number
    id: number
    external_id: number
    type: number
    account_name: number
    bank: number
    device_name: number
    device_token: number
    method: number
    number: number
    rub_amount: number
    price: number
    rate_source: number
    usdt_amount: number
    reward_total: number
    reward_crossbank: number
    reward: number
    total_usdt_amount: number
    comment: number
    status: number
    entity?: number
}