import style from "./AddAccount.module.scss";
import {Modal} from "@mui/material";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../../state/store";
import {SettingType} from "../../../state/reducers/setting-reducer";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import * as Yup from "yup";
import React, {useEffect} from "react";
import {useFormik} from "formik";
import {ReactComponent as Cross} from "../../../assets/images/cross.svg";
import {editAccountTC, getNewAccountTC} from "../../../state/reducers/accounts-reducer";
import {modalsActions} from "../../../state/reducers/modals-reducer";
import {AccountType, EditAccountParamsType} from "../../../state/models/AccountResponse";
import {useTranslation} from "react-i18next";
import {NameFilter} from "../../../components/filters/NameFilter";
import {BankFilter} from "../../../components/filters/BanksFilter";
import {DeviceFilter} from "../../../components/filters/DevicesFilter";
import {DailyLimitFilter} from "../../../components/filters/DayliLimitFilter";
import {MonthLimitFilter} from "../../../components/filters/MonthLimitFilter";


const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string().required('Поле обязательно'),
    bank: Yup.string(),
    device_id: Yup.number().required('Поле обязательно'),
    day_limit: Yup.number().required('Поле обязательно').min(0, 'Минимальное значение 0'),
    month_limit: Yup.number().required('Поле обязательно').min(0, 'Минимальное значение 0'),
});


export const EditAccount = () => {
    const id = useSelector<AppRootStateType, number | null>(state => state.modals.editAccount);
    const acc = useSelector<AppRootStateType, AccountType | null>(state => state.accounts.definiteAccount);
    const dispatch = useAppDispatch();

    const toggleShowModal = () => {
        dispatch(modalsActions.changeStateOfAccountEditModal(null))
    }

    const INITIAL_FORM_STATE: EditAccountParamsType = {
        name: acc?.name,
        bank: acc?.bank,
        day_limit: acc?.day_limit,
        month_limit: acc?.month_limit,
    }
    const closeModalHandler = () => {
        formik.resetForm()
        toggleShowModal()
    }

    const formik = useFormik({
        initialValues: INITIAL_FORM_STATE,
        validationSchema: VALIDATION_SCHEMA,
        onSubmit: async (values, action) => {
            const applyObj: EditAccountParamsType = {}
            applyObj.bank = values.bank
            applyObj.name = values.name
            if (values.device_id) {
                applyObj.device_id = +values.device_id
            }
            if (id) {
                const res = await dispatch(editAccountTC(id, applyObj))
                if (!res) {
                    formik.setFieldError('name', 'Пользователь с таким именем уже существуем');
                } else {
                    closeModalHandler()
                }
            }
        }
    });

    useEffect(() => {
        if (id) {
            dispatch(getNewAccountTC(id))
        }
    }, [id]);

    useEffect(() => {
        formik.resetForm({
            values: {
                name: acc?.name,
                bank: acc?.bank,
                device_id: acc?.device_id,
                day_limit: acc?.day_limit,
                month_limit: acc?.month_limit
            }
        })

    }, [acc]);


    const {t} = useTranslation();


    return (
        <Modal className={style.WrapperModal}
               open={!!id}
               style={{zIndex: '9999999999'}}>
            <form className={style.Container} onSubmit={formik.handleSubmit}>
                <div className={style.HeaderContainer}>
                    <h3>{t('editAccountModal.title')}</h3>
                    <button>
                        <Cross onClick={closeModalHandler}/>
                    </button>
                </div>
                <div className={style.FiltersContainer}>
                    <NameFilter formik={formik}/>
                    <BankFilter formik={formik} type={'extended'}/>
                    <DeviceFilter formik={formik} type={'extended'}/>
                    <DailyLimitFilter formik={formik}/>
                    <MonthLimitFilter formik={formik}/>
                </div>
                <div className={style.BtnsContainer}>
                    <button className={style.CancelBtn} type={'button'} onClick={closeModalHandler}>
                        {t('cancelBtn')}
                    </button>
                    <button
                        type={'submit'}
                        className={`${style.ApplyBtn} ${(!formik.isValid || !formik.dirty) && style.BtnApplyDisabled}`}
                        disabled={!formik.isValid || !formik.dirty}>
                        {t('saveBtn')}
                    </button>
                </div>
            </form>
        </Modal>
    )
}