import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export type ConfirmModalType = {
    type: 'block' | 'turnOn'
    title: string
    desc: string
    closeBtn: string
    confirmBtn: string
    onClose?: any
    onConfirm?: () => void
};
export type PayInPayoutModalType = {
    id: number
    title: string
    type: 'payin' | 'payout' | 'withdrawal'
    actionBtn: string
    balance: number
};
export type PayInPayoutParamsType = {
    type: 'payin' | 'payout' | 'withdrawal'
    amount: number | null
    user_id: number | null
    comment?: string
    target_address?: string
};
export type AddRequisiteModalType = {
    type?: 'account'
    account_id?: number
    bank?: string
    open:boolean
}
type  InitialStateType = {
    confirm: ConfirmModalType | null
    editUser: number | null
    payInOutUser: PayInPayoutModalType | null
    withdrawalUser: PayInPayoutModalType | null
    editAccount: number | null
    editRequisite: number | null
    addRequisite: AddRequisiteModalType | null
    addDevice: boolean
    openDispute: boolean
    editDevice: number | null
    addRegisterByOrders: boolean
    addRegisterByFinances: boolean
    orderStatus: string | null
};
const initialState: InitialStateType = {
    confirm: null,
    editUser: null,
    editAccount: null,
    editRequisite: null,
    payInOutUser: null,
    withdrawalUser: null,
    addRequisite: null,
    addDevice: false,
    openDispute: false,
    editDevice: null,
    addRegisterByOrders: false,
    addRegisterByFinances: false,
    orderStatus: null
}

const slice = createSlice({
    name: "modals",
    initialState: initialState,
    reducers: {
        setConfirmModal: (state, action: PayloadAction<ConfirmModalType | null>) => {
            state.confirm = action.payload
        },
        changeStateOfUserEditModal: (state, action: PayloadAction<number | null>) => {
            state.editUser = action.payload
        },
        changeStateOfAccountEditModal: (state, action: PayloadAction<number | null>) => {
            state.editAccount = action.payload
        },
        changeStateOfRequisiteEditModal: (state, action: PayloadAction<number | null>) => {
            state.editRequisite = action.payload
        },
        changeStateOfAddRequisite: (state, action: PayloadAction<AddRequisiteModalType | null>) => {
            state.addRequisite = action.payload
        },
        changeStateOfAddDevice: (state, action: PayloadAction<boolean>) => {
            state.addDevice = action.payload
        },
        changeStateOfOpenDispute: (state, action: PayloadAction<boolean>) => {
            state.openDispute = action.payload
        },
        changeStateOfDeviceEditModal: (state, action: PayloadAction<number | null>) => {
            state.editDevice = action.payload
        },
        changeStateOfRegisterByOrders: (state, action: PayloadAction<boolean>) => {
            state.addRegisterByOrders = action.payload
        },
        changeStateOfRegisterByFinances: (state, action: PayloadAction<boolean>) => {
            state.addRegisterByFinances = action.payload
        },
        changeStateOfOrderStatus: (state, action: PayloadAction<string | null>) => {
            state.orderStatus = action.payload
        },
        setPayInOutUserModal: (state, action: PayloadAction<PayInPayoutModalType | null>) => {
            state.payInOutUser = action.payload
        },
        setWithdrawalUserModal: (state, action: PayloadAction<PayInPayoutModalType | null>) => {
            state.withdrawalUser = action.payload
        },
    }
});
export const modalsReducer = slice.reducer;
export const modalsActions = slice.actions;


