import style from "../../accounts/modals/AddAccount.module.scss";
import React from "react";
import Modal from "@mui/material/Modal";
import {modalsActions} from "../../../state/reducers/modals-reducer";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../../state/store";
import {ReactComponent as Cross} from "../../../assets/images/cross.svg";
import {useFormik} from "formik";
import * as Yup from "yup";
import {TextFieldCustom} from "../../../components/textField/TextField";
import {NumberTextField} from "../../../components/numberTextField/NumberTextField";
import {BasicSelect} from "../../../components/basicSelect/BasicSelect";
import {NewDisputeParamsType, orderTypesConst} from "../../../state/models/DisputeResponse";
import {setNewDisputeTC} from "../../../state/reducers/dispute-reducer";


const VALIDATION_SCHEMA = Yup.object({
    order_id: Yup.string().required('Поле обязательно'),
    id_type: Yup.string().required('Поле обязательно'),
});

const INITIAL_FORM_STATE = {
    id_type: '',
    order_id: '',
    amount: null,
    message: ""
}


export const OpenDisputeModal = () => {
    const state = useSelector<AppRootStateType, boolean>(state => state.modals.openDispute);

    const dispatch = useAppDispatch();

    const closeModalHandler = () => {
        formik.resetForm()
        dispatch(modalsActions.changeStateOfOpenDispute(false));
    }
    const formik = useFormik({
        initialValues: INITIAL_FORM_STATE,
        validationSchema: VALIDATION_SCHEMA,
        onSubmit: async (values, action) => {
            let applyObj: NewDisputeParamsType = {}
            if (values.amount !== null) {
                applyObj.amount = values.amount
            }
            if (values.message !== '') {
                applyObj.message = values.message.trim()
            }
            applyObj.id_type = values.id_type;
            applyObj.order_id = values.order_id;


            const res = await dispatch(setNewDisputeTC(applyObj))
            if (!res) {

                formik.setFieldError('order_id', 'Проверьте правильность введенных данных');
            } else {
                closeModalHandler()
            }
        }
    })

    return (
        <Modal className={style.WrapperModal}
               open={state}
               style={{zIndex: '9999999999'}}>
            <form className={style.Container} onSubmit={formik.handleSubmit}>
                <div className={style.HeaderContainer}>
                    <h3>Открыть диспут</h3>
                    <button>
                        <Cross onClick={closeModalHandler}/>
                    </button>
                </div>
                <div className={style.FiltersContainer}>
                    <BasicSelect label={'Выберите тип ID ордера'} title={'id_type'} formik={formik}
                                 items={orderTypesConst}/>
                    <TextFieldCustom title={'Order ID'} formik={formik} formikValue={'order_id'}/>
                    <NumberTextField formikValue={'amount'} formik={formik} title={'Сумма'}
                                     error={formik.errors.amount}/>
                    <TextFieldCustom title={'Сообщение'} formik={formik} formikValue={'message'}/>
                </div>
                <div className={style.BtnsContainer}>
                    <button className={style.CancelBtn} type={'button'} onClick={closeModalHandler}>
                        Отмена
                    </button>
                    <button
                        type={'submit'}
                        className={`${style.ApplyBtn} ${(!formik.isValid || !formik.dirty) && style.BtnApplyDisabled}`}
                        disabled={!formik.isValid || !formik.dirty}>
                        Открыть
                    </button>
                </div>
            </form>
        </Modal>
    )
}