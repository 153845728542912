import {AxiosResponse} from "axios";
import {$api} from "../http";
import {getToken} from "../../utils/utils";
import {AccountParamsType, AccountResponseType, AccountType, NewAccountParamsType} from "../models/AccountResponse";
import {ApplyObjType} from "../../pages/accounts/userFilters/AccountsFilters";


export const AccountsService = {
    fetchAccounts(params: ApplyObjType): Promise<AxiosResponse<AccountResponseType>> {
        return $api.get(`/accounts`, {
            params,
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    setNewAccount(params: NewAccountParamsType): Promise<AxiosResponse<AccountType>> {
        return $api.post(`/accounts`, params, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    getNewAccount(id: number): Promise<AxiosResponse<AccountType>> {
        return $api.get(`/accounts/${id}`, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    editAccount(id: number, params: NewAccountParamsType): Promise<AxiosResponse<AccountType>> {
        return $api.put(`/accounts/${id}`, params, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }
}


