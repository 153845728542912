import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {TextFieldCustom} from "../textField/TextField";

type PropsType = {
    formik: any
}

export const AutoPaySecretKeyFilter: FC<PropsType> = ({formik}) => {
    const {t} = useTranslation();

    return (
        <>
            <TextFieldCustom title={t('addDevice.payout_token')}
                             formik={formik} formikValue={'payout_token'}/>
        </>
    )
}