import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getRole, getToken, removeToken, setRoleToStorage, setToken} from "../../utils/utils";
import {AuthService, LoginType} from "../services/AuthService";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import {AxiosError} from "axios";
import {handleServerNetworkError} from "../../utils/error-utils";
import {AuthResponse} from "../models/AuthResponse";

export type RoleType = 'user' | 'admin' | 'support'

export type InitialStateType = {
    isLoggedIn: boolean
    role: string | null
}

const initialState: InitialStateType = {
    isLoggedIn: Boolean(getToken()),
    role: getRole()
}

const slice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setIsLoggedIn: (state, action: PayloadAction<{ isLoggedIn: boolean }>) => {
            state.isLoggedIn = action.payload.isLoggedIn;
        },
        setNewRole: (state, action: PayloadAction<{ role: RoleType }>) => {
            state.role = action.payload.role;
        }
    },
});
export const authReducer = slice.reducer;
export const authActions = slice.actions;


export const loginTC = (data: LoginType): AppThunk<Promise<AuthResponse | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await AuthService.login(data)
            dispatch(authActions.setIsLoggedIn({isLoggedIn: true}));
            dispatch(authActions.setNewRole({role: res.data.user.role}));

            dispatch(appActions.setAppStatus({status: "succeeded"}));
            setToken(res.data.token);
            setRoleToStorage(res.data.user.role)

        } catch (err) {
            if (err instanceof AxiosError){
                // handleServerNetworkError(err, dispatch)

                if (err.response?.status === 401) {
                    dispatch(authActions.setIsLoggedIn({isLoggedIn: false}));
                }
                if (err.response?.status === 423) {
                    dispatch(authActions.setIsLoggedIn({isLoggedIn: false}));
                    dispatch(appActions.setAppStatus({status: "Locked"}));
                }else{
                    dispatch(appActions.setAppStatus({status: "failLogin"}));
                }
            }
        };
    };

export const logoutTC = (): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            await AuthService.logout(getToken());
            removeToken();
            // deleteRole();
            dispatch(authActions.setIsLoggedIn({isLoggedIn: false}));
        } catch (err) {
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }
    };



