import style from "./BasicSelect.module.scss";
import {MenuItem, Select} from "@mui/material";
import React, {useState} from "react";
import {AccountSettingType, SettingType} from "../../state/reducers/setting-reducer";
import {FormikProvider} from "formik";
import {useTranslation} from "react-i18next";


interface Props {
    title: string;
    items: SettingType[] | AccountSettingType[]
    formik: any
    label: string
    readonly?: boolean
    val?: string
    setSelectedBank?: (value: string) => void
    fetchBalance?: (id:number) => void
    type?: string
}

export const BasicSelect: React.FC<Props> = ({
                                                 title,
                                                 setSelectedBank,
                                                 fetchBalance,
                                                 readonly,
                                                 formik,
                                                 items,
                                                 label,
                                                 type
                                             }) => {

    const [open, setOpen] = useState(false)

    const selectBank = (bank: string) => {
        if (title === 'account_id') {
            if (setSelectedBank) {
                setSelectedBank(bank)
            }
        }
    }

    const {t} = useTranslation();

    return (
        <FormikProvider value={formik}>
            <div className={style.FilterBox} key={title}>
                <div className={style.TitleContainer}>
                    <p>
                        {label}
                    </p>
                </div>
                {!formik.values[title] ?
                    <div className={style.NotSelected} onClick={() => setOpen(!open)}>{t('notChosen')}</div> : ''}
                <Select
                    {...formik.getFieldProps(title)}
                    labelId="demo-simple-select-standard-label"
                    variant={'standard'}
                    open={open}
                    onClose={() => setOpen(!open)}
                    onOpen={() => setOpen(!open)}
                    className={`${style.Select} ${readonly && style.ReadOnlySelect} ${formik.values[title] === t('notChosen') && style.NotSelectedForm}`}
                    // defaultValue={formik.values[title] || 'yt ds,hfyj'}
                    value={formik.values[title] || null}
                    label={label}
                    disabled={readonly}
                    placeholder={label}
                >
                    {
                        items.map((item: AccountSettingType, index) => {
                            return <MenuItem value={item.value} onClick={() => {
                                if (item.bank) {
                                    selectBank(item.bank)
                                }
                                if (type === 'balance' && fetchBalance){
                                    fetchBalance(item.value)
                                }
                            }} className={style.MenuItem} key={index}>
                                {item.name}
                            </MenuItem>
                        })
                    }
                </Select>
            </div>
        </FormikProvider>
    )
}