import {BasicSelect} from "../basicSelect/BasicSelect";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {SettingType} from "../../state/reducers/setting-reducer";
import {useTranslation} from "react-i18next";
import MultipleSelect2 from "../multipleSelect/MultipleSelect2";

type PropsType = {
    formik: any
    type?: 'extended'
    typeOfSelect?: 'multiple' | 'basic'
}

export const AccountFilter: FC<PropsType> = ({formik, type, typeOfSelect}) => {
    const {t} = useTranslation();
    const accounts = useSelector<AppRootStateType, SettingType[]>(state => state.settings.accounts);
    const defaultVal = t('notChosen');

    const defaulFiltertObj = {
        value: defaultVal, name: defaultVal
    }
    const items = accounts && type !== 'extended' ? [defaulFiltertObj, ...accounts] : accounts
    return (
        <>
            {accounts && (typeOfSelect !== 'multiple'
                ? <BasicSelect label={t("filters.account")} title={'account_id'} formik={formik}
                               items={items}/>
                : <MultipleSelect2 setFieldValue={formik.setFieldValue} title={t("filters.account")}
                                   formikValue={'account_id'} formik={formik} items={accounts}/>)
            }
        </>
    )
}