import style from "./EditUser.module.scss";
import {Modal} from "@mui/material";
import {ReactComponent as Cross} from "../../../assets/images/cross.svg";
import {TextFieldCustom} from "../../../components/textField/TextField";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {userStatusesForModal} from "../../../constants/Examples";
import {PasswordTextField} from "../../../components/passwordTextField/PasswordTextField";
import {BasicSelect} from "../../../components/basicSelect/BasicSelect";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../../state/store";
import {SettingType} from "../../../state/reducers/setting-reducer";
import {NumberTextField} from "../../../components/numberTextField/NumberTextField";
import {TextFieldMultiLine} from "../../../components/textFieldMultiLine/TextFieldMultiLine";
import {editUserTC, getDefiniteUserTC} from "../../../state/reducers/users-reducer";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {SetUserParamsType, UserType} from "../../../state/models/UsersResponse";
import * as Yup from "yup";
import {modalsActions} from "../../../state/reducers/modals-reducer";
import {UserNameFilter} from "../../../components/filters/UserNameFilter";
import { PasswordFilter } from "../../../components/filters/PassswordFilter";
import {StatusFilter} from "../../../components/filters/StatusFilter";
import {RateSourceFilter} from "../../../components/filters/RateSourceFilter";
import {RewardPayInFilter} from "../../../components/filters/RewardPayinFilter";
import {RewardPayOutFilter} from "../../../components/filters/RewardPayOutFilter";
import {DailyLimitFilter} from "../../../components/filters/DayliLimitFilter";
import {useTranslation} from "react-i18next";


const VALIDATION_SCHEMA = Yup.object({
    username: Yup.string().required('Поле обязательно'),
    status: Yup.string().required('Поле обязательно'),
    merchant_id: Yup.string(),
    daily_limit: Yup.number().required('Поле обязательно'),
    rate_source: Yup.string().required('Поле обязательно'),
    reward_payin: Yup.number().required('Поле обязательно'),
    reward_payout: Yup.number().required('Поле обязательно'),
});

export const EditUserComponent = () => {
    const id = useSelector<AppRootStateType, number | null>(state => state.modals.editUser);
    const sources = useSelector<AppRootStateType, SettingType[]>(state => state.settings.rate_sources);
    const user = useSelector<AppRootStateType, UserType | null>(state => state.users.definiteUser);


    useEffect(() => {
        if (id) {
            dispatch(getDefiniteUserTC(id))
        }
    }, [id]);

    const INITIAL_FORM_STATE = {
        username: user?.username,
        password: '',
        status: user?.status,
        merchant_id: user?.merchant_id.join(''),
        daily_limit: user?.daily_limit,
        rate_source: user?.rate_source,
        reward_payin: user?.reward_payin,
        reward_payout: user?.reward_payout,
    };
    const [password, setPassword] = useState('');

    const closeModalHandler = () => {
        setPassword('')
        formik.resetForm({})
        dispatch(modalsActions.changeStateOfUserEditModal(null))
    }
    const formik = useFormik({
        initialValues: INITIAL_FORM_STATE,
        validationSchema: VALIDATION_SCHEMA,
        onSubmit: async (values, action) => {
            const applyObj: SetUserParamsType = {};
            applyObj.username = values.username;
            if (values.password !== '') {
                applyObj.password = password;
            }
            applyObj.daily_limit = values.daily_limit;
            applyObj.reward_payin = values.reward_payin;
            applyObj.reward_payout = values.reward_payout;
            applyObj.status = values.status;
            applyObj.rate_source = values.rate_source;

            if (values.merchant_id !== '') {
                applyObj.merchant_id = values.merchant_id?.replace(/\s/g, "").split(',');
            } else {
                applyObj.merchant_id = [];
            }
            if (id) {
                const res = await dispatch(editUserTC(id, applyObj));
                closeModalHandler()
                if (!res) {
                    formik.setFieldError('username', 'Пользователь с таким именем уже существуем');
                }
            }

        }

    })
    const dispatch = useAppDispatch();

    useEffect(() => {
        ('how match')
        formik.resetForm({
            values: {
                username: user?.username,
                password: '',
                status: user?.status,
                merchant_id: user?.merchant_id.join(', '),
                daily_limit: user?.daily_limit,
                rate_source: user?.rate_source,
                reward_payin: user?.reward_payin,
                reward_payout: user?.reward_payout,
            }
        })

    }, [user]);

    const passLength = 12


    const generatePassword = () => {
        const numbers = '1234567890';
        const symbols = '!@#$%^&*()_+{}[]|:;<>,.?/~';
        let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

        chars += numbers;
        chars += symbols;

        let generatedPassword = '';
        for (let i = 0; i < passLength; i++) {
            const randomIndex = Math.floor(Math.random() * chars.length);
            generatedPassword += chars[randomIndex];
        }
        setPassword(generatedPassword);
        formik.setFieldValue('password', generatedPassword);
    };
    const {t} = useTranslation();
    return (
        <Modal className={style.WrapperModal}
               open={!!id}
               style={{zIndex: '9999999999'}}>
            <form className={style.Container} onSubmit={formik.handleSubmit}>
                <div className={style.HeaderContainer}>
                    <h3>{t('editUserModal.title')}</h3>
                    <button type={"button"}>
                        <Cross onClick={closeModalHandler}/>
                    </button>
                </div>
                <div className={style.FiltersContainer}>
                    <UserNameFilter formik={formik} disabled={true}/>
                    <PasswordFilter password={password} generatePassword={generatePassword} formik={formik}/>
                    <StatusFilter formik={formik} items={userStatusesForModal} type={'extended'}/>
                    <RateSourceFilter formik={formik}/>
                    <div className={style.RewardBlock}>
                        <RewardPayInFilter formik={formik}/>
                        <RewardPayOutFilter formik={formik}/>
                    </div>
                    <DailyLimitFilter formik={formik}/>
                    <TextFieldMultiLine formikValue={'merchant_id'} formik={formik}
                                        title={'Merchant IDs'}/>
                </div>
                <div className={style.BtnsContainer}>
                    <button className={style.CancelBtn} type={'button'} onClick={closeModalHandler}>
                        {t('cancelBtn')}
                    </button>
                    <button
                        type={'submit'}
                        className={`${style.ApplyBtn} ${(!formik.isValid || !formik.dirty) && style.BtnApplyDisabled}`}
                        disabled={!formik.isValid || !formik.dirty}>
                        {t('saveBtn')}
                    </button>
                </div>
            </form>
        </Modal>
    )
}