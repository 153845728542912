import {AxiosResponse} from "axios";
import {$api} from "../http";
import {getToken} from "../../utils/utils";
import {SettingsStateType} from "../reducers/setting-reducer";


export default class SettingsService {
    static async  fetchSettings(params: string): Promise<AxiosResponse<SettingsStateType>> {
        return $api.get(`/settings?names=${encodeURIComponent(params)}`, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }
}


