import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {TextFieldCustom} from "../textField/TextField";
import {BasicSelect} from "../basicSelect/BasicSelect";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {SettingType} from "../../state/reducers/setting-reducer";
import MultipleSelect2 from "../multipleSelect/MultipleSelect2";

type PropsType = {
    formik: any
    typeOfSelect?: 'multiple' | 'basic'
}

export const RateSourceFilter: FC<PropsType> = ({formik, typeOfSelect}) => {
    const {t} = useTranslation();
    const sources = useSelector<AppRootStateType, SettingType[]>(state => state.settings.rate_sources);

    return (
        <>
            {sources && (
                typeOfSelect !== 'multiple'
                    ? <BasicSelect label={t('filters.rate_source')} title={'rate_source'} formik={formik}
                                   items={sources}/>
                    : <MultipleSelect2 setFieldValue={formik.setFieldValue} title={t("filters.rate_source")}
                                       formikValue={'rate_source'}
                                       formik={formik} items={sources}/>
            )}
        </>
    )
}