import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import SettingsService from "../services/SettingsService";

export type SettingType = {
    name: string,
    value: any
    url?: string
}
export type AccountSettingType = {
    name: string,
    value: any
    bank?: string
}
export type  SettingsStateType = {
    banks: SettingType[]
    chains: SettingType[]
    methods: SettingType[]
    rate_sources: SettingType[]
    transaction_types: SettingType[]
    order_types: SettingType[]
    users: SettingType[]
    accounts: AccountSettingType[]
    devices: SettingType[]
}
const initialState: SettingsStateType = {
    banks: [],
    chains: [],
    methods: [],
    rate_sources: [],
    transaction_types: [],
    order_types: [],
    users: [],
    accounts: [],
    devices: [],
}

const slice = createSlice({
    name: "settings",
    initialState: initialState,
    reducers: {
        setSettingsActions: (state: SettingsStateType, action: PayloadAction<SettingsStateType>) => {
            return action.payload
        },
    },
});
export const settingsReducer = slice.reducer;
export const settingsActions = slice.actions;


export const fetchSettingsTC = (params: string): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(appActions.setAppStatus({status: "loading"}));
            const res = await SettingsService.fetchSettings(params);
            dispatch(settingsActions.setSettingsActions(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        } catch
            (err) {
            dispatch(appActions.setAppStatus({status: "failed"}));
        } finally {
            dispatch(appActions.setAppInitialized({isInitialized: true}));
        }

    }
// async (dispatch) => {
//      dispatch(appActions.setAppStatus({status: "loading"}));
//     const res = await SettingsService.fetchSettings(params);
//     res.then((res) => {
//          dispatch(settingsActions.setSettingsActions(res.data));
//          dispatch(appActions.setAppStatus({status: "succeeded"}));
//      })
//          .catch(err => {
//              handleServerNetworkError(err, dispatch)
//              dispatch(appActions.setAppStatus({status: "failed"}));
//          });
//  };

