import style from "./AddDevice.module.scss";
import {Modal} from "@mui/material";
import {ReactComponent as Cross} from "../../../assets/images/cross.svg";
import {TextFieldCustom} from "../../../components/textField/TextField";
import {useFormik} from "formik";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {NumberTextField} from "../../../components/numberTextField/NumberTextField";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import * as Yup from "yup";
import {AppRootStateType} from "../../../state/store";
import {DeviceType, EditDeviceParamsType} from "../../../state/models/Deviceresponse";
import {editDeviceTC, getDefiniteDeviceTC} from "../../../state/reducers/device-reducer";
import {modalsActions} from "../../../state/reducers/modals-reducer";
import {NameFilter} from "../../../components/filters/NameFilter";
import {AutoPaySecretKeyFilter} from "../../../components/filters/AutoPaySecretKey";
import {ApplicationSecretKeyFilter} from "../../../components/filters/ApplicationSecretKey";
import {DailyLimitFilter} from "../../../components/filters/DayliLimitFilter";
import {MonthLimitFilter} from "../../../components/filters/MonthLimitFilter";
import {useTranslation} from "react-i18next";

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string().required('Поле обязательно'),
});


export const EditDeviceModal = () => {
    const device = useSelector<AppRootStateType, DeviceType | null>(id => id.devices.definiteDevice);
    const id = useSelector<AppRootStateType, number | null>(id => id.modals.editDevice);

    const dispatch = useAppDispatch();
    const INITIAL_FORM_STATE: EditDeviceParamsType = {
        name: '',
        payin_token: '',
        payout_token: '',
        telegram_id: '',
        tx_min: null,
        tx_max: null,
        daily_limit: null,
        month_limit: null,
    };

    useEffect(() => {
        if (id) {
            dispatch(getDefiniteDeviceTC(id))
        }
    }, [id]);

    useEffect(() => {
        formik.resetForm({
            values: {
                name: device?.name,
                payin_token: device?.payin_app?.token,
                payout_token: device?.payout_app?.token,
                telegram_id: device?.payout_telegram?.telegram_id,
                tx_min: device?.tx_min,
                tx_max: device?.tx_max,
                daily_limit: device?.daily_limit,
                month_limit: device?.month_limit,
            }
        })
    }, [device]);

    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(modalsActions.changeStateOfDeviceEditModal(null))
    }

    const formik = useFormik({
            initialValues: INITIAL_FORM_STATE,
            validationSchema: VALIDATION_SCHEMA,
            onSubmit: async (values, action) => {
                const applyObj: EditDeviceParamsType = {}
                if (values.payin_token !== '') {
                    applyObj.payin_token = values.payin_token
                }
                if (values.payout_token !== '') {
                    applyObj.payout_token = values.payout_token
                }
                if (values.telegram_id !== '') {
                    applyObj.telegram_id = values.telegram_id
                }
                if (values.tx_min !== null) {
                    applyObj.tx_min = values.tx_min
                }
                if (values.tx_max !== null) {
                    applyObj.tx_max = values.tx_max
                }
                if (values.daily_limit !== null) {
                    applyObj.daily_limit = values.daily_limit
                }
                if (values.month_limit !== null) {
                    applyObj.month_limit = values.month_limit
                }
                applyObj.name = values.name
                if (id) {
                    const res = await dispatch(editDeviceTC(id, applyObj));

                    if (!res) {
                        formik.setFieldError('name', 'Устройство с таким названием уже существуем');
                    } else {
                        closeModalHandler()
                    }
                }
            }

        })
    ;

    const {t} = useTranslation();

    return (
        <Modal className={style.WrapperModal}
               open={!!id}
               style={{zIndex: '9999999999'}}>
            <form className={style.Container} onSubmit={formik.handleSubmit}>
                <div className={style.HeaderContainer}>
                    <h3>Изменить устройство</h3>
                    <button>
                        <Cross onClick={closeModalHandler}/>
                    </button>
                </div>
                <div className={style.FiltersContainer}>
                    <NameFilter formik={formik}/>
                    <TextFieldCustom title={'Telegram ID'} formik={formik} formikValue={'telegram_id'}/>
                    <AutoPaySecretKeyFilter formik={formik}/>
                    <ApplicationSecretKeyFilter formik={formik}/>
                    <div className={style.RewardBlock}>
                        <DailyLimitFilter formik={formik}/>
                        <MonthLimitFilter formik={formik}/>
                    </div>
                    <div className={style.RestrictionBlock}>
                        <p>{t('addDevice.limits')}</p>
                        <div>
                            <div>
                                <p>{t('addDevice.from')}</p>
                                <NumberTextField formikValue={'tx_min'} formik={formik}
                                                 title={''}
                                                 placeholder={'Введите сумму'}
                                                 error={formik.errors.tx_min}/>
                            </div>
                            <div>
                                <p>{t('addDevice.to')}</p>
                                <NumberTextField formikValue={'tx_max'} formik={formik}
                                                 title={''}
                                                 placeholder={'Введите сумму'}
                                                 error={formik.errors.tx_max}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.BtnsContainer}>
                    <button className={style.CancelBtn} type={'button'} onClick={closeModalHandler}>
                        {t('cancelBtn')}
                    </button>
                    <button
                        type={'submit'}
                        className={`${style.ApplyBtn} ${(!formik.isValid || !formik.dirty) && style.BtnApplyDisabled}`}
                        disabled={!formik.isValid || !formik.dirty}>
                        {t('saveBtn')}
                    </button>
                </div>
            </form>
        </Modal>
    )
}