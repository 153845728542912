import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../../utils/error-utils";
import {
    AccountParamsType,
    AccountResponseType,
    AccountSettingsState,
    AccountSettingsType, AccountType, EditAccountParamsType, NewAccountParamsType
} from "../models/AccountResponse";
import {AccountsService} from "../services/AccountService";
import {AxiosError} from "axios";
import {showSuccessToast} from "../../utils/utils";
import {ApplyObjType, ApplyObjType2} from "../../pages/accounts/userFilters/AccountsFilters";

type  InitialStateType = {
    filters: ApplyObjType
    filtersStorage: ApplyObjType2
    accounts: AccountResponseType
    settings: AccountSettingsType
    definiteAccount: AccountType | null
}
const initialState: InitialStateType = {
    filters: JSON.parse(localStorage.getItem('accountFilters') || JSON.stringify({})),
    filtersStorage: JSON.parse(localStorage.getItem('accountFiltersStorage') || JSON.stringify([])),
    settings: JSON.parse(localStorage.getItem('accountSettings') || JSON.stringify(AccountSettingsState)),
    accounts: {
        count: 1,
        page: 1,
        page_size: 1,
        results: [],
        total_pages: 1
    },
    definiteAccount: null
}

const slice = createSlice({
    name: "account",
    initialState: initialState,
    reducers: {
        setAccounts: (state, action: PayloadAction<AccountResponseType>) => {
            state.accounts = action.payload
            // debugger
        },
        setAccountSettings: (state, action: PayloadAction<AccountSettingsType>) => {
            state.settings = action.payload
            localStorage.setItem('accountSettings', JSON.stringify(action.payload));
        },
        setAccountFilters: (state, action: PayloadAction<ApplyObjType>) => {
            state.filters = action.payload
            localStorage.setItem('accountFilters', JSON.stringify(action.payload));
        },
        setAccountStorageFilters: (state, action: PayloadAction<ApplyObjType2>) => {
            state.filtersStorage = action.payload
            localStorage.setItem('accountFiltersStorage', JSON.stringify(action.payload));
        },
        setNewAccount: (state, action: PayloadAction<AccountType>) => {
            state.accounts.results.unshift(action.payload);
        },
        getNewAccount: (state, action: PayloadAction<AccountType>) => {
            state.definiteAccount = action.payload
        },
        setAccountUpdates: (state: InitialStateType, action: PayloadAction<AccountType>) => {
            const index = state.accounts.results.findIndex((t) => t.id === action.payload.id);
            if (index !== -1) {
                state.accounts.results[index] = {...action.payload};
            }
        },
    },
});
export const accountReducer = slice.reducer;
export const accountActions = slice.actions;


export const fetchAccountsTC = (data: ApplyObjType): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        AccountsService.fetchAccounts(data).then((res) => {
            dispatch(accountActions.setAccounts(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

export const setNewAccountTC = (data: NewAccountParamsType): AppThunk<Promise<AccountType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await AccountsService.setNewAccount(data);
            dispatch(accountActions.setNewAccount(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };

export const getNewAccountTC = (id: number): AppThunk<Promise<AccountType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await AccountsService.getNewAccount(id);

            dispatch(accountActions.getNewAccount(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };

export const editAccountTC = (id: number, params: EditAccountParamsType): AppThunk<Promise<AccountType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await AccountsService.editAccount(id, params);
            dispatch(accountActions.setAccountUpdates(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            showSuccessToast('Действие выполнено успешно');
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };


