import {AxiosResponse} from "axios";
import {$api} from "../http";
import {getToken} from "../../utils/utils";
import {ProfileResponseType} from "../models/ProfileResponse";


export const ProfileService = {
    fetchProfiles(): Promise<AxiosResponse<ProfileResponseType>> {
        return $api.get('/users/profile', {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    setNewPass(params: string): Promise<AxiosResponse<ProfileResponseType>> {
        return $api.post('/users/password', {password: params}, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }

}

