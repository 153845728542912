import {registersTypeEnum} from "../../enums/enums";
import {TransactionTypes} from "./TransactionResponse";

export type RegisterParamsType = {
    start_date?: string
    end_date?: string
    type?: string
    user_id?: string
    page?: number
    page_size?: number
}
export type NewRegisterParamsType = {
    name?: string
    type?: string
    start_date?: string
    end_date?: string
    order_type?: string
    transaction_type?: string
    instance_status?: string
    order_method?: string
    device_id?: number | null
    account_id?: number | null
    user_id?: number | null
    number?: string
    bank?: string
    source_address?: string
    target_address?: string
}
export const transactionTypesForRegisters = [
    {
        name: TransactionTypes.deposit,
        value: TransactionTypes.deposit,
    }, {
        name: TransactionTypes.payin,
        value: TransactionTypes.payin,
    }, {
        name: TransactionTypes.payout,
        value: TransactionTypes.payout,
    }, {
        name: TransactionTypes.withdrawal,
        value: TransactionTypes.withdrawal,
    }
]
export type RegisterResponseType = {
    count: number
    page: number
    page_size: number
    total_pages: number
    results: RegisterType[]
}

export type RegisterType = {
    id: number
    name: string
    type: registersTypeEnum
    created_at: string
    finalized_at: string
    start_date: string
    end_date: string
    order_type: string
    transaction_type: string
    instance_status: string
    order_method: string
    number: string
    bank: string
    source_address: string
    target_address: string
    status: string
    orders_count: number
    download_url: string
    device: {
        name: string,
    },
    account: {
        name: string,
    },
    user: {
        username: string
    },
}

export enum registerTypesEnum {
    orders = 'По ордерам',
    transactions = 'По финансам',
}


export enum registerStatusesEnum {
    processing = 'processing',
    successful = 'successful',
    failed = 'failed',
}

export const registerTypesConst = [
    {
        name: registerTypesEnum.orders,
        value: 'orders',
    }, {
        name: registerTypesEnum.transactions,
        value: 'transactions',
    },
];

enum instance_statusEnum {
    created = 'created',
    pending = 'pending',
    processing = 'processing',
    accept = 'accept',
    decline = 'decline',
    error = 'error',
}

export const instance_StatusesConst = [
    {
        name: 'Created',
        value: instance_statusEnum.created,
    }, {
        name: 'Pending',
        value: instance_statusEnum.pending,
    }, {
        name: 'Processing',
        value: instance_statusEnum.processing,
    }, {
        name: 'Accept',
        value: instance_statusEnum.accept,
    }, {
        name: 'Decline',
        value: instance_statusEnum.decline,
    }, {
        name: 'Error',
        value: instance_statusEnum.error,
    },
]
export type RegisterSettingsType = {
    id?: boolean
    name?: boolean
    type?: boolean
    params?: boolean
    created_at?: boolean
    finalized_at?: boolean
    status?: boolean
    download_url?: boolean
}
export type NamesRegisterType = "id"
    | "name"
    | "type"
    | "params"
    | "created_at"
    | "finalized_at"
    | "status"
    | "download_url"

export const registerColumnOrder = ["id", "name", "type", "params", "created_at", "finalized_at", "status", "download_url"]

export type RegisterColumnSizeType = {
    id: number
    name: number
    type: number
    params: number
    created_at: number
    finalized_at: number
    status: number
    download_url: number
}
export const RegisterSettingsState = {
    id: true,
    name: true,
    type: true,
    params: true,
    created_at: true,
    finalized_at: true,
    status: true,
    download_url: true,
}