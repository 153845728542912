import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import {
    SetUserParamsType,
    UserSettingsState,
    UserSettingsType,
    UsersFilterType,
    UsersResponse,
    UserType, UserUpdatesStateType
} from "../models/UsersResponse";
import {UsersService} from "../services/UserService";
import {handleServerNetworkError} from "../../utils/error-utils";
import {showSuccessToast} from "../../utils/utils";
import {AxiosError} from "axios";
import {UsersFilterForSelectsType} from "../../pages/users/userFilters/UserFilters";
import {ApplyObjType2} from "../../pages/accounts/userFilters/AccountsFilters";

type  InitialStateType = {
    filters: UsersFilterType
    filtersForSelects: UsersFilterForSelectsType
    users: UsersResponse
    settings: typeof UserSettingsState
    definiteUser: UserType | null
}
const initialState: InitialStateType = {
    filters: JSON.parse(localStorage.getItem('userFilters') || JSON.stringify({})),
    filtersForSelects: JSON.parse(localStorage.getItem('userFiltersForSelects') || JSON.stringify({})),
    settings: JSON.parse(localStorage.getItem('userSettings') || JSON.stringify(UserSettingsState)),
    users: {
        count: 1,
        page: 1,
        page_size: 1,
        results: [],
        total_pages: 1
    },
    definiteUser: null
}

const slice = createSlice({
    name: "users",
    initialState: initialState,
    reducers: {
        setUsers: (state, action: PayloadAction<UsersResponse>) => {
            state.users = action.payload
            // debugger
        },
        setUsersSettings: (state, action: PayloadAction<UserSettingsType>) => {
            state.settings = action.payload
            localStorage.setItem('userSettings', JSON.stringify(action.payload));
        },
        setUsersFilters: (state, action: PayloadAction<UsersFilterType>) => {
            state.filters = action.payload
            localStorage.setItem('userFilters', JSON.stringify(action.payload));
        },
        setUserFiltersForSelects: (state, action: PayloadAction<UsersFilterForSelectsType>) => {
            state.filtersForSelects = action.payload
            localStorage.setItem('userFiltersForSelects', JSON.stringify(action.payload));
        },
        setNewUser: (state: InitialStateType, action: PayloadAction<UserType>) => {
            state.users.results.unshift(action.payload)
        },
        setUserUpdates: (state: InitialStateType, action: PayloadAction<UserType>) => {
            const index = state.users.results.findIndex((t) => t.id === action.payload.id);
            if (index !== -1) {
                state.users.results[index] = {...action.payload};
            }
        },
        getEditUser: (state: InitialStateType, action: PayloadAction<UserType>) => {
            state.definiteUser = action.payload
        },

    },
});
export const usersReducer = slice.reducer;
export const usersActions = slice.actions;


export const fetchUsersTC = (data: UsersFilterType): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        UsersService.fetchUsers(data).then((res) => {
            dispatch(usersActions.setUsers(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

export const setNewUserTC = (data: SetUserParamsType): AppThunk<Promise<UserType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await UsersService.setUser(data);
            dispatch(usersActions.setNewUser(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            showSuccessToast('Действие выполнено успешно');

            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };

export const editUserTC = (id: number, params: SetUserParamsType): AppThunk<Promise<UserUpdatesStateType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await UsersService.editUser(id, params);
            dispatch(usersActions.setUserUpdates(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            showSuccessToast('Действие выполнено успешно');
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };

export const getDefiniteUserTC = (id: number): AppThunk<Promise<UserType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await UsersService.getDefiniteUser(id);
            dispatch(usersActions.getEditUser(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            // showSuccessToast('Действие выполнено успешно');
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };


