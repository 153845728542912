export type DisputeResponseType = {
    count: number
    page: number
    page_size: number
    results: DisputeType[]
    total_pages: number
}
export type DisputeType = {
    manager_username: string
    trader_username: string
    order: {
        id: string
        created_at: string
        usdt_amount: number
        status: string
    },
    dispute_order: {
        id: string
        usdt_amount: number
        created_at: string
        updated_at: string
        status: disputeStatusEnum
    },
    messages_count: number
    alerts_count: number
}
export type DisputeSettingsType = {
    created_at?: boolean
    updated_at?: boolean
    manager_username?: boolean
    trader_username?: boolean
    order_id?: boolean
    order_created_at?: boolean
    order_amount?: boolean
    order_status?: boolean
    dispute_id?: boolean
    dispute_amount?: boolean
    dispute_status?: boolean
    messages_count?: boolean
    alerts_count?: boolean
    entity?: boolean
}
export type NamesDisputeType = 'created_at'
    | "updated_at"
    | "manager_username"
    | "trader_username"
    | "order_id"
    | "order_created_at"
    | "order_amount"
    | "order_status"
    | "dispute_id"
    | "dispute_amount"
    | "dispute_status"
    | "messages_count"
    | "alerts_count"
    | "entity"
export const disputeColumnOrder = ['created_at', "updated_at", "manager_username", "trader_username", "order_id", "order_created_at", "order_amount", "order_status", "dispute_id", "dispute_amount", "dispute_status", "messages_count", "alerts_count", "entity"]

export const DisputeSettingsState = {
    created_at: true,
    updated_at: true,
    manager_username: true,
    trader_username: true,
    order_id: true,
    order_created_at: true,
    order_amount: true,
    order_status: true,
    dispute_id: true,
    dispute_amount: true,
    dispute_status: true,
    messages_count: true,
    alerts_count: true,
}
export type DisputeColumnSizesType = {
    created_at: number
    updated_at: number
    manager_username: number
    trader_username: number
    order_id: number
    order_created_at: number
    order_amount: number
    order_status: number
    dispute_id: number
    dispute_amount: number
    dispute_status: number
    messages_count: number
    alerts_count: number
    entity?: number
}
export type DisputeParamsType = {
    dispute_order_id?: string
    end_date?: string
    start_date?: string
    order_id?: string
    page?: number
    page_size?: number
    status?: string
    user_id?: string
}
export type NewDisputeParamsType = {
    id_type?: string
    order_id?: string
    amount?: number
    message?: string
}
export type EditDisputeParamsType = {
    dispute_id: string
    status: disputeStatusEnum
    finalized_by: string
}

export enum finalized_byEnum {
    processing = "processing",
    support = "support",
    client = "client",
}

export enum disputeStatusEnum {
    accept = 'accept',
    decline = 'decline',
    processing = 'processing'
}

export const disputeTypesConst = [
    {
        name: disputeStatusEnum.accept,
        value: disputeStatusEnum.accept,
    }, {
        name: disputeStatusEnum.decline,
        value: disputeStatusEnum.decline,
    }, {
        name: disputeStatusEnum.processing,
        value: disputeStatusEnum.processing,
    },
];

const enum orderTypesEnum {
    processing_id = 'processing_id',
    id = 'id'
}

export const orderTypesConst = [
    {
        name: 'Processing ID',
        value: orderTypesEnum.processing_id,
    }, {
        name: 'ID',
        value: orderTypesEnum.id,
    },
]