import {AxiosResponse} from "axios";
import {$api} from "../http";
import {getToken} from "../../utils/utils";
import {NewOrderParamsType, OrderParamsType, OrderResponseType, OrderType} from "../models/OrderResponse";


export const OrderService = {
    fetchOrders(params: OrderParamsType): Promise<AxiosResponse<OrderResponseType>> {
        return $api.get('/orders', {
            params: params,
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    getDefiniteOrders(id: string): Promise<AxiosResponse<OrderType>> {
        return $api.get(`/orders/${id}`, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },
    setOrderChanges(params: NewOrderParamsType): Promise<AxiosResponse<OrderType>> {
        return $api.put(`/orders/${params.id}`, {status: params.status}, {
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    },

}

