import React, {FC} from "react";
import {useTranslation} from "react-i18next";

import {NumberTextField} from "../numberTextField/NumberTextField";

type PropsType = {
    formik: any
}

export const AmountFilter: FC<PropsType> = ({formik}) => {
    const {t} = useTranslation();

    return (
        <>
            <NumberTextField formikValue={'amount'} formik={formik}
                             title={t('filters.amount')}
                             error={formik.errors.amount}/>
        </>
    )
}