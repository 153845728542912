import {AxiosResponse} from "axios";
import {$api} from "../http";
import {getToken} from "../../utils/utils";
import {StatisticsParamsType, StatisticsResponseType } from "../models/StatisticsResponse";

export const StatisticsService = {
    fetchStatistics(params: StatisticsParamsType): Promise<AxiosResponse<StatisticsResponseType>> {
        return $api.get('/stats', {
            params,
            headers: {Authorization: `Token ${getToken() || 'lup lup lup'}`},
        })
    }

}

