import React from 'react'
import style from './DefaultLayout.module.scss';
import {NavbarComponent as Navbar} from "../components/navBar/Navbar";
import MainContent from "../components/mainContent/MainContent";
import {LinearProgress} from "@mui/material";
import {ToastContainer} from "react-toastify";
import './Toast.css'
import 'react-toastify/dist/ReactToastify.css'
import {ConfirmModal} from "../components/confirmModal/ConfirmModal";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../state/store";
import {RequestStatusType} from "../state/reducers/appReducer";
import {EditUserComponent} from "../pages/users/editUser/EditUserComponent";
import {EditAccount} from "../pages/accounts/modals/EditAccount";
import {EditRequisite} from "../pages/requisites/modals/Editrequisite";
import {AddRequisite} from '../pages/requisites/modals/AddRequisit';
import {AddDeviceModal} from "../pages/devices/modals/AddDevice";
import {EditDeviceModal} from "../pages/devices/modals/EditDevice";
import {AddRegisterByOrders} from "../pages/registers/modals/AddRegisterByOrders";
import {AddRegisterByFinances} from "../pages/registers/modals/AddRegisterByFinances";
import {OrderStatusModal} from '../pages/orders/modals/ChangeStatusModal';
import {PayInOutModal} from "../pages/users/payIn/PayIn";
import {WithDrawlModal} from "../pages/users/payIn/Withdrawal";
import {OpenDisputeModal} from "../pages/disputes/modals/OpenDispute";
import {ReactComponent as IconErr} from "../assets/images/warning.svg";
import {ReactComponent as IconSuccess} from "../assets/images/successIcon.svg";

const DefaultLayout = () => {

    const status = useSelector<AppRootStateType, RequestStatusType>(state => state.app.status);
    return (
        <div className={style.Wrapper}>
            <Navbar/>
            {
                status === 'loading' && <LinearProgress className={style.Linear} style={{zIndex: '999999999'}}/>
            }
            <div className={style.Container}>
                <MainContent/>
            </div>
            <PayInOutModal/>
            <WithDrawlModal/>
            <ConfirmModal/>
            <EditAccount/>
            <EditUserComponent/>
            <OpenDisputeModal/>
            <EditRequisite/>
            <AddRequisite/>
            <AddDeviceModal/>
            <EditDeviceModal/>
            <AddRegisterByOrders/>
            <AddRegisterByFinances/>
            <OrderStatusModal/>

            <ToastContainer
                position="bottom-center"
                icon={(type) => type.type === 'error' ? <IconErr/> : <IconSuccess/>}
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeButton={false}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </div>
    )
}

export default DefaultLayout