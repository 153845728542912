import React, {FC} from "react";
import {useTranslation} from "react-i18next";

import {TextFieldCustom} from "../textField/TextField";

type PropsType = {
    formik: any
}

export const CommentFilter: FC<PropsType> = ({formik}) => {
    const {t} = useTranslation();

    return (
        <>
            <TextFieldCustom title={t('filters.comment')} formik={formik} formikValue={'comment'}/>
        </>
    )
}