import React, {FC} from "react";
import {TextFieldCustom} from "../textField/TextField";
import {useTranslation} from "react-i18next";

type PropsType = {
    formik: any
    disabled?: boolean
}

export const UserNameFilter: FC<PropsType> = ({formik, disabled}) => {
    const {t} = useTranslation();

    return (
        <>
            <TextFieldCustom title={t('filters.userName')} disabled={disabled} formik={formik} formikValue={'username'}/>
        </>
    )
}