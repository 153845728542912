import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../../utils/error-utils";
import {DeviceService} from "../services/DeviceService";
import {
    AddDeviceParamsType,
    DeviceParamsType,
    DeviceResponseType,
    DeviceSettingsState,
    DeviceSettingsType,
    DeviceType, EditDeviceParamsType
} from "../models/Deviceresponse";
import {showSuccessToast} from "../../utils/utils";
import {AxiosError} from "axios";
import {DeviceFiltersForSelects} from "../../pages/devices/userFilters/DeviceFilters";


type  InitialStateType = {
    filters: DeviceParamsType
    filtersForSelects: DeviceFiltersForSelects
    devices: DeviceResponseType
    settings: DeviceSettingsType
    definiteDevice: DeviceType | null
}
const initialState: InitialStateType = {
    filters: JSON.parse(localStorage.getItem('deviceFilters') || JSON.stringify({})),
    filtersForSelects: JSON.parse(localStorage.getItem('deviceFiltersForSelects') || JSON.stringify({})),
    settings: JSON.parse(localStorage.getItem('deviceSettings') || JSON.stringify(DeviceSettingsState)),
    devices: {
        count: 1,
        page: 1,
        page_size: 1,
        results: [],
        total_pages: 1
    },
    definiteDevice: null
}

const slice = createSlice({
    name: "device",
    initialState: initialState,
    reducers: {
        setDevices: (state, action: PayloadAction<DeviceResponseType>) => {
            state.devices = action.payload
        },
        setDeviceSettings: (state, action: PayloadAction<DeviceSettingsType>) => {
            state.settings = action.payload
            localStorage.setItem('deviceSettings', JSON.stringify(action.payload));
        },
        setDeviceFilters: (state, action: PayloadAction<DeviceParamsType>) => {
            state.filters = action.payload
            localStorage.setItem('deviceFilters', JSON.stringify(action.payload));
        },
        setDeviceFiltersForSelects: (state, action: PayloadAction<DeviceFiltersForSelects>) => {
            state.filtersForSelects = action.payload
            localStorage.setItem('deviceFiltersForSelects', JSON.stringify(action.payload));
        },
        setDefiniteDevice: (state, action: PayloadAction<DeviceType>) => {
            state.definiteDevice = action.payload
        },
        addDevice: (state, action: PayloadAction<DeviceType>) => {
            state.devices.results.unshift(action.payload)
        },
        setDevicesUpdates: (state: InitialStateType, action: PayloadAction<DeviceType>) => {
            const index = state.devices.results.findIndex((t) => t.id === action.payload.id);
            if (index !== -1) {
                state.devices.results[index] = {...action.payload};
            }
        },
    },
});
export const deviceReducer = slice.reducer;
export const deviceActions = slice.actions;


export const fetchDevicesTC = (data: DeviceParamsType): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        DeviceService.fetchDevices(data).then((res) => {
            dispatch(deviceActions.setDevices(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

export const addDeviceTC = (params: AddDeviceParamsType): AppThunk<Promise<DeviceType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await DeviceService.addDevice(params);
            dispatch(deviceActions.addDevice(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            showSuccessToast('Действие выполнено успешно');
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };

export const getDefiniteDeviceTC = (id: number): AppThunk<Promise<DeviceType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await DeviceService.getDefiniteDevice(id);
            dispatch(deviceActions.setDefiniteDevice(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };

export const editDeviceTC = (id: number, params: EditDeviceParamsType): AppThunk<Promise<DeviceType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await DeviceService.editDevice(id, params);
            dispatch(deviceActions.setDevicesUpdates(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            showSuccessToast('Действие выполнено успешно');
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };


