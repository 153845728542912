import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {NumberTextField} from "../numberTextField/NumberTextField";

type PropsType = {
    formik: any
}

export const RewardPayInFilter: FC<PropsType> = ({formik}) => {
    const {t} = useTranslation();

    return (
        <>
            <NumberTextField formikValue={'reward_payin'} formik={formik}
                             type={'reward'}
                             title={t('filters.reward_payin')}
                             error={formik.errors.reward_payin}/>
        </>
    )
}