import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {NumberTextField} from "../numberTextField/NumberTextField";

type PropsType = {
    formik: any
}

export const DailyLimitFilter: FC<PropsType> = ({formik}) => {
    const {t} = useTranslation();

    return (
        <>
            <NumberTextField formikValue={'daily_limit'} formik={formik}
                             title={t('filters.daily_limit')}
                             placeholder={t('addDevice.enterAmount')}
                             error={formik.errors.daily_limit}/>
        </>
    )
}