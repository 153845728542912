import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk} from "../store";
import {appActions} from "./appReducer";
import {handleServerNetworkError} from "../../utils/error-utils";
import {
    AddRequisiteParamsType, EditRequisiteParamsType,
    RequisiteSettingsType,
    RequisitesParamsType,
    RequisitesResponseType, RequisitesSettingsState, RequisitType,

} from "../models/RequisitesResponse";
import RequisitesService from "../services/RequisitesService";
import {fetchSettingsTC} from "./setting-reducer";
import {showSuccessToast} from "../../utils/utils";
import {AxiosError} from "axios";
import {RequisiteFiltersForSelectsType} from "../../pages/requisites/requisitesFilters/RequisitesFilters";


type  InitialStateType = {
    filters: RequisitesParamsType
    filtersForSelects: RequisiteFiltersForSelectsType
    requisites: RequisitesResponseType
    settings: RequisiteSettingsType
    definiteRequisite: RequisitType | null
}
const initialState: InitialStateType = {
    filters: JSON.parse(localStorage.getItem('requisiteFilters') || JSON.stringify({})),
    filtersForSelects: JSON.parse(localStorage.getItem('requisiteFiltersForSelects') || JSON.stringify({})),
    settings: JSON.parse(localStorage.getItem('requisiteSettings') || JSON.stringify(RequisitesSettingsState)),
    requisites: {
        count: 1,
        page: 1,
        page_size: 1,
        results: [],
        total_pages: 1
    },
    definiteRequisite: null
}

const slice = createSlice({
    name: "requisites",
    initialState: initialState,
    reducers: {
        setRequisites: (state, action: PayloadAction<RequisitesResponseType>) => {
            state.requisites = action.payload
        },
        setRequisitesSettings: (state, action: PayloadAction<RequisiteSettingsType>) => {
            state.settings = action.payload
            localStorage.setItem('requisiteSettings', JSON.stringify(action.payload));
        },
        setRequisitesFilters: (state, action: PayloadAction<RequisitesParamsType>) => {
            state.filters = action.payload
            localStorage.setItem('requisiteFilters', JSON.stringify(action.payload));
        },
        setRequisitesFiltersForSelect: (state, action: PayloadAction<RequisiteFiltersForSelectsType>) => {
            state.filtersForSelects = action.payload
            localStorage.setItem('requisiteFiltersForSelects', JSON.stringify(action.payload));
        },
        setDefiniteRequisite: (state, action: PayloadAction<RequisitType>) => {
            state.definiteRequisite = action.payload
        },
        addRequisite: (state, action: PayloadAction<RequisitType>) => {
            state.requisites.results.unshift(action.payload)
        },
        setRequisitesUpdates: (state: InitialStateType, action: PayloadAction<RequisitType>) => {
            const index = state.requisites.results.findIndex((t) => t.id === action.payload.id);
            if (index !== -1) {
                state.requisites.results[index] = {...action.payload};
            }
        },
    },
});
export const requisiteReducer = slice.reducer;
export const requisiteActions = slice.actions;


export const fetchRequisitesTC = (data: RequisitesParamsType): AppThunk =>
    (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        RequisitesService.fetchRequisites(data).then((res) => {
            dispatch(requisiteActions.setRequisites(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));

        })
            .catch(err => {
                handleServerNetworkError(err, dispatch)
                dispatch(appActions.setAppStatus({status: "failed"}));
            });
    };

export const getDefiniteRequisiteTC = (id: number): AppThunk<Promise<RequisitType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await RequisitesService.getDefiniteRequisite(id);
            dispatch(requisiteActions.setDefiniteRequisite(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };

export const editRequisiteTC = (id: number, params: EditRequisiteParamsType): AppThunk<Promise<RequisitType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await RequisitesService.editRequisite(id, params);
            dispatch(requisiteActions.setRequisitesUpdates(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            showSuccessToast('Действие выполнено успешно');
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };
export const addRequisiteTC = (params: AddRequisiteParamsType): AppThunk<Promise<RequisitType | void>> =>
    async (dispatch) => {
        dispatch(appActions.setAppStatus({status: "loading"}));
        try {
            const res = await RequisitesService.addRequisite(params);
            dispatch(requisiteActions.addRequisite(res.data));
            dispatch(appActions.setAppStatus({status: "succeeded"}));
            showSuccessToast('Действие выполнено успешно');
            return res.data
        } catch (err) {
            if (err instanceof AxiosError)
                handleServerNetworkError(err, dispatch)
            dispatch(appActions.setAppStatus({status: "failed"}));
        }
    };

