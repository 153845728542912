import {Modal} from "@mui/material";
import style from './ModalAddBalance.module.scss';
import React, {FC, useEffect} from "react";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {fetchProfileTC} from "../../state/reducers/profile-reducer";
import {ReactComponent as Cross} from "../../assets/images/cross.svg";
import {QRCodeSVG} from "qrcode.react";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {copyLink} from "../../utils/utils";
import {BasicSelect} from "../basicSelect/BasicSelect";
import {SettingType} from "../../state/reducers/setting-reducer";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";

interface ConfirmModal {
    show: boolean
    handleToggle: () => void
}

export const ModalAddBalance: FC<ConfirmModal> = ({handleToggle, show}) => {
    const dispatch = useAppDispatch();
    const address = useSelector<AppRootStateType, string>(state => state.profile.state.address);
    const chains = useSelector<AppRootStateType, SettingType[]>(state => state.settings.chains);

    useEffect(() => {
        dispatch(fetchProfileTC())

    }, []);
    const currencyArr = [{
        name: 'USDT',
        value: 'usdt'
    }]

    const INITIAL_FORM_STATE = {
        chain: '',
    }
    const formik = useFormik({
            initialValues: INITIAL_FORM_STATE,
            onSubmit: (values, action) => {

            }
        }
    )

    useEffect(() => {
        formik.setFieldValue('chain', 'trc20')
        formik.setFieldValue('currency', 'usdt')
    }, [show]);
    const {t} = useTranslation();

    return (
        <Modal className={style.WrapperModal}
               open={show}
               onClose={handleToggle}
               aria-labelledby="modal-modal-title"
               aria-describedby="modal-modal-description"
               style={{zIndex: '9999999999'}}>
            <div className={style.Container}>
                <div className={style.Header}>
                    <h4>
                        {t('addBalanceModal.title')}
                    </h4>
                    <Cross onClick={handleToggle} className={style.Cross}/>
                </div>
                <div className={style.Filters}>
                    <BasicSelect label={t('addBalanceModal.currency')} title={'currency'} readonly={true} formik={formik}
                                 items={currencyArr}/>
                    {chains &&
                        <BasicSelect label={t('addBalanceModal.chain')} title={'chain'} readonly={true} formik={formik} items={chains}/>}
                </div>
                <p className={style.Desc}>
                    {t('addBalanceModal.Desc1')}
                </p>
                <p className={style.Desc}>
                    {t('addBalanceModal.Desc2')}
                </p>
                <div className={style.QRContainer}>
                    <div className={style.QRBlock}>
                        <QRCodeSVG value={address} width={'120px'} height={'120px'}/>
                    </div>
                    <div className={style.QRDesc}>
                        <p>{t('addBalanceModal.qrDesc')}</p>
                        <p className={style.Address}>{address}</p>
                        <p className={style.Copy}
                           onClick={() => copyLink(address)}>{t('addBalanceModal.copyAddress')}</p>
                    </div>
                </div>
                <button className={style.CancelBtn} onClick={handleToggle}>{t('cancelBtn')}</button>
            </div>
        </Modal>
    )
}