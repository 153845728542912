import style from "./AddRegisters.module.scss";
import {Modal} from "@mui/material";
import {ReactComponent as Cross} from "../../../assets/images/cross.svg";
import {TextFieldCustom} from "../../../components/textField/TextField";
import {useFormik} from "formik";
import React, {useState} from "react";
import {useSelector} from "react-redux";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import * as Yup from "yup";
import {AppRootStateType} from "../../../state/store";
import {modalsActions} from "../../../state/reducers/modals-reducer";
import {
    instance_StatusesConst,
    NewRegisterParamsType, transactionTypesForRegisters,
} from "../../../state/models/RegisterResponse";
import {DateComponent} from "../../../components/dateComponent/DateComponent";
import {BasicSelect} from "../../../components/basicSelect/BasicSelect";
import {SettingType} from "../../../state/reducers/setting-reducer";
import {formatDateForApi} from "../../../utils/utils";
import {setNewRegisterTC} from "../../../state/reducers/register-reducer";
import {useTranslation} from "react-i18next";
import {UserFilter} from "../../../components/filters/UserFilter";

const VALIDATION_SCHEMA = Yup.object({
    name: Yup.string().required('Поле обязательно'),
});


export const AddRegisterByFinances = () => {
    const state = useSelector<AppRootStateType, boolean>(id => id.modals.addRegisterByFinances);
    const orderTypes = useSelector<AppRootStateType, SettingType[]>(state => state.settings.order_types);
    const users = useSelector<AppRootStateType, SettingType[]>(state => state.settings.users);
    const {t} = useTranslation();

    const [dateRange, setDateRange] = useState<any>([null, null]);
    const [startDate, endDate] = dateRange;
    const dispatch = useAppDispatch();
    const INITIAL_FORM_STATE: NewRegisterParamsType = {
        name: '',
        transaction_type: '',
        start_date: '',
        end_date: '',
        instance_status: '',
        user_id: null,
        source_address: '',
        target_address: '',
    };


    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(modalsActions.changeStateOfRegisterByFinances(false))
    }

    const formik = useFormik({
        initialValues: INITIAL_FORM_STATE,
        validationSchema: VALIDATION_SCHEMA,
        onSubmit: async (values, action) => {
            const applyObj: NewRegisterParamsType = {}
            if (values.name !== '') {
                applyObj.name = values.name
            }
            if (values.transaction_type !== '') {
                applyObj.transaction_type = values.transaction_type
            }
            if (values.instance_status !== '') {
                applyObj.instance_status = values.instance_status
            }
            if (values.source_address !== '') {
                applyObj.source_address = values.source_address
            }
            if (values.target_address !== '') {
                applyObj.target_address = values.target_address
            }
            if (startDate !== null) {
                applyObj.start_date = formatDateForApi(startDate)
            }
            if (endDate !== null) {
                applyObj.end_date = formatDateForApi(endDate)
            }
            if (values.user_id !== null) {
                applyObj.user_id = values.user_id
            }
            applyObj.type = 'transactions'
            const res = await dispatch(setNewRegisterTC(applyObj));
            if (!res) {
                formik.setFieldError('name', 'Реестр с таким названием уже существуем');
            } else {
                closeModalHandler()
            }

        }

    })


    return (
        <Modal className={style.WrapperModal}
               open={state}
               style={{zIndex: '9999999999'}}>
            <form className={style.Container} onSubmit={formik.handleSubmit}>
                <div className={style.HeaderContainer}>
                    <h3>{t('registers.financeRegisterTitle')}</h3>
                    <button>
                        <Cross onClick={closeModalHandler}/>
                    </button>
                </div>
                <div className={style.FiltersContainer}>
                    <TextFieldCustom title={t('registers.registerName')} formik={formik} formikValue={'name'}/>
                    <DateComponent setDateRange={setDateRange} endDate={endDate} startDate={startDate}/>
                    {orderTypes && <BasicSelect label={t('registers.operation_type')} title={'transaction_type'} formik={formik}
                                                items={transactionTypesForRegisters}/>}
                    <BasicSelect label={t('registers.payStatus')} title={'instance_status'} formik={formik}
                                 items={instance_StatusesConst}/>
                    <div className={style.FullWidthBlock}>
                        <UserFilter formik={formik} type={'extended'}/>
                    </div>
                    <div className={style.FullWidthBlock}>
                        <TextFieldCustom title={t('transactions.source_address')} formik={formik} formikValue={'source_address'}/>
                    </div>
                    <div className={style.FullWidthBlock}>
                        <TextFieldCustom title={t('transactions.target_address')} formik={formik} formikValue={'target_address'}/>
                    </div>


                </div>
                <div className={style.BtnsContainer}>
                    <button className={style.CancelBtn} type={'button'} onClick={closeModalHandler}>
                        {t('cancelBtn')}
                    </button>
                    <button
                        type={'submit'}
                        className={`${style.ApplyBtn} ${(!formik.isValid || !formik.dirty) && style.BtnApplyDisabled}`}
                        disabled={!formik.isValid || !formik.dirty}>
                        {t('saveBtn')}
                    </button>
                </div>
            </form>
        </Modal>
    )
}