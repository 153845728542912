import {BasicSelect} from "../basicSelect/BasicSelect";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {SettingType} from "../../state/reducers/setting-reducer";
import {useTranslation} from "react-i18next";
import MultipleSelect2 from "../multipleSelect/MultipleSelect2";

type PropsType = {
    formik: any
    type?: 'extended'
    typeOfSelect?: 'multiple' | 'basic'
}

export const DeviceFilter: FC<PropsType> = ({formik, type, typeOfSelect}) => {
    const {t} = useTranslation();
    const devices = useSelector<AppRootStateType, SettingType[]>(state => state.settings.devices);
    const defaultVal = t('notChosen');

    const defaulFiltertObj = {
        value: defaultVal, name: defaultVal
    }
    const items = devices && type !== 'extended' ? [defaulFiltertObj, ...devices] : devices;
    return (
        <>
            {devices && (typeOfSelect !== 'multiple'
                ? <BasicSelect label={t("filters.device")} title={'device_id'} formik={formik}
                             items={items}/>
                : <MultipleSelect2 setFieldValue={formik.setFieldValue} title={t("filters.device")}
                                   formikValue={'device_id'}
                                   formik={formik} items={devices}/>)}
        </>
    )
}