import {FormikProvider} from "formik";
import style from "./PasswordTextField.module.scss";
import {IconButton, Input, InputAdornment} from "@mui/material";
import React, {FC, useState} from "react";
import {ReactComponent as Visibility} from "../../assets/images/Visibility.svg";
import {ReactComponent as VisibilityOff} from "../../assets/images/VisibilityOff.svg";
import {CopyComponent} from "../copy/CopyComponent";
import {useTranslation} from "react-i18next";

interface TextFieldI {
    title: string
    error: string | undefined
    formik: any
    formikValue: string
    readOnly?: boolean
    generatedValue?: string
    generatePassword?: () => void
}


export const PasswordTextField: FC<TextFieldI> = ({
                                                      title,
                                                      formik,
                                                      generatePassword,
                                                      generatedValue,
                                                      readOnly,
                                                      formikValue,
                                                      error
                                                  }) => {
    const [showPassword, setShowPassword] = useState(false);

    const {t} = useTranslation();
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };


    return (
        <FormikProvider value={formik}>
            <div className={style.FilterBox}>
                <div className={style.Desc}>
                    <p>
                        {title}
                    </p>
                    {
                        readOnly &&
                        <p onClick={generatePassword} className={style.GenerateTitle}>{t('addUserModal.generatePassword')}</p>
                    }
                </div>

                <Input
                    id={formikValue}
                    type={showPassword ? 'text' : 'password'}
                    {...formik.getFieldProps(formikValue)}
                    value={readOnly ? generatedValue : formik[formikValue]}
                    className={`${style.TextField} ${error && style.ErrorTextField} ${readOnly && style.ReadOnlyTextField}`}
                    placeholder={title}
                    readOnly={readOnly}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                            </IconButton>
                            {
                                (readOnly &&  generatedValue) && <IconButton
                                    aria-label="toggle password visibility">
                                    <CopyComponent value={generatedValue}/>
                                </IconButton>
                            }
                        </InputAdornment>
                    }
                />
                {error ? <div className={style.Error}>{error}</div> : null}
            </div>

        </FormikProvider>
    )
}