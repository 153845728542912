import React, {useEffect} from "react";
import style from "./PayinModal.module.scss";
import {Modal} from "@mui/material";
import {ReactComponent as Cross} from "../../../assets/images/cross.svg";
import {BasicSelect} from "../../../components/basicSelect/BasicSelect";
import * as Yup from "yup";
import {useFormik} from "formik";
import {useAppDispatch} from "../../../hooks/useAppDispatch";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../../state/store";
import {modalsActions, PayInPayoutModalType, PayInPayoutParamsType} from "../../../state/reducers/modals-reducer";
import {TextFieldCustom} from "../../../components/textField/TextField";
import {numberWithSpaces} from "../../../utils/utils";
import {setPayInOutTransactionTC} from "../../../state/reducers/transactions-reducer";
import {fetchUsersTC} from "../../../state/reducers/users-reducer";
import {UsersFilterType} from "../../../state/models/UsersResponse";
import {fetchDefiniteBalanceTC} from "../../../state/reducers/balance-reducer";
import {useTranslation} from "react-i18next";
import {UserFilter} from "../../../components/filters/UserFilter";
import {CommentFilter} from "../../../components/filters/CommentFilter";
import {AmountFilter} from "../../../components/filters/AmountFilter";


const INITIAL_FORM_STATE = {
    user_id: '',
    amount: null,
    comment: '',
    source: '',
    target_address:''
};

const sourceArr = [{
    name: 'Tron',
    value: 1,
}]
export const WithDrawlModal = () => {
    const state = useSelector<AppRootStateType, PayInPayoutModalType | null>(state => state.modals.withdrawalUser);
    const balanceApi = useSelector<AppRootStateType, number | null>(state => state.balance.state.definiteUserBalance);
    const filters = useSelector<AppRootStateType, UsersFilterType>(state => state.users?.filters);
    const currentPage = useSelector<AppRootStateType, number>(state => state.users?.users?.page);
    const dispatch = useAppDispatch();
    const balance = balanceApi ? balanceApi : 0;


    const VALIDATION_SCHEMA_PAY_OUT = Yup.object({
        user_id: Yup.string().required('Поле обязательно'),
        target_address: Yup.string().required('Поле обязательно'),
        amount: Yup.number().required('Поле обязательно').positive('Сумма должна быть больше 0').lessThan(balance + 1, 'Cумма не должна превышать лимит'),
    })
    const closeModalHandler = () => {
        formik.resetForm();
        dispatch(modalsActions.setWithdrawalUserModal(null))
    };

    useEffect(() => {
        if (state) {
            dispatch(fetchDefiniteBalanceTC(state?.id))
        }
    }, [state]);

    const formik = useFormik({
        initialValues: INITIAL_FORM_STATE,
        validationSchema: VALIDATION_SCHEMA_PAY_OUT,
        onSubmit: async (values, action) => {
            if (state) {
                const applyObj: PayInPayoutParamsType = {
                    type: state.type,
                    amount: values.amount,
                    target_address: values.target_address,
                    user_id: +values.user_id,
                }
                if (values.comment !== '') {
                    applyObj.comment = values.comment;
                }
                const res = await dispatch(setPayInOutTransactionTC(applyObj));

                if (res) {
                    dispatch(fetchUsersTC({
                        ...filters,
                        page: currentPage,
                        page_size: Number(localStorage.getItem('perPageUsers')) || 1
                    }))
                    closeModalHandler()
                } else {
                    // formik.setFieldError('name', 'Пользователь с таким именем уже существуем');
                }
            }
        }
    })
    useEffect(() => {
        formik.setFieldValue('user_id', state?.id)
        formik.setFieldValue('source', 1)
    }, [state]);

    const {t} = useTranslation();

    return (
        <Modal className={style.WrapperModal}
               open={!!state}
               style={{zIndex: '9999999999'}}>
            <form className={style.Container} onSubmit={formik.handleSubmit}>
                <div className={style.HeaderContainer}>
                    <h3>{state?.title}</h3>
                    <button>
                        <Cross onClick={closeModalHandler}/>
                    </button>
                </div>
                <div className={style.FiltersContainer}>
                    <UserFilter formik={formik}/>
                    <BasicSelect label={t('filters.network')} title={'source'} formik={formik} readonly={true}
                                 items={sourceArr}/>
                    <TextFieldCustom title={t('filters.address')} placeholder={t('filters.addressPlaceHolder')} formik={formik}
                                     formikValue={'target_address'}/>
                    <div className={style.AmountBlock}>
                        <AmountFilter formik={formik}/>
                        {/*<NumberTextField formikValue={'amount'} formik={formik}*/}
                        {/*                 title={'Введите сумму'}*/}
                        {/*                 error={formik.errors.amount}/>*/}
                        <div className={style.AvailableAmount}>
                            <p className={style.Available}>{t('withdrawModal.availableAmount')}</p>
                            <p className={style.AvalableBalance} onClick={() => {
                                formik.setFieldValue('amount', state?.balance);
                            }}>{balanceApi && numberWithSpaces(balanceApi)}</p>
                        </div>
                    </div>
                    <CommentFilter formik={formik}/>
                </div>
                <div className={style.BtnsContainer}>
                    <button className={style.CancelBtn} type={'button'} onClick={closeModalHandler}>
                        {t('cancelBtn')}
                    </button>
                    <button
                        type={'submit'}
                        className={`${style.ApplyBtn} ${(!formik.isValid || !formik.dirty) && style.BtnApplyDisabled}`}
                        disabled={!formik.isValid || !formik.dirty}>
                        {state?.actionBtn}
                    </button>
                </div>
            </form>
        </Modal>
    )
}