import {accountStatusesEnum} from "./AccountResponse";

export type TransactionParamsType = {
    end_date?: string
    start_date?: string
    hash?: string
    page?: number
    page_size?: number
    source_address?: string
    status?: string
    target_address?: string
    type?: string
    user_id?: string
}

export enum TransactionStatuses {
    decline = 'decline',
    accept = 'accept',
    error = 'error',
    pending = 'pending',
}

export enum TransactionTypes {
    deposit = 'deposit',
    payin = 'payin',
    payout = 'payout',
    withdrawal = 'withdrawal',
}

export const transactionsConstTypes = [
    {
        name: TransactionTypes.deposit,
        value: TransactionTypes.deposit,
    }, {
        name: TransactionTypes.payin,
        value: TransactionTypes.payin,
    }, {
        name: TransactionTypes.payout,
        value: TransactionTypes.payout,
    }, {
        name: TransactionTypes.withdrawal,
        value: TransactionTypes.withdrawal,
    }
]
export const transactionsConstStatuses = [
    {
        name: TransactionStatuses.decline,
        value: TransactionStatuses.decline,
    }, {
        name: TransactionStatuses.accept,
        value: TransactionStatuses.accept,
    }, {
        name: TransactionStatuses.error,
        value: TransactionStatuses.error,
    }, {
        name: TransactionStatuses.pending,
        value: TransactionStatuses.pending,
    }
]

export type TransactionResponseType = {
    count: number
    total_pages: number
    page: number
    page_size: number
    results: TransactionType[]
}
export type TransactionType = {
    id: number
    created_at: string
    type: TransactionTypes
    transaction_timestamp: number
    tx_hash: string
    amount: number
    source_address: string
    target_address: string
    status: TransactionStatuses
    comment: string
    user: number
}

export const TransactionSettingsState = {
    created_at: true,
    id: true,
    source_address: true,
    target_address: true,
    tx_hash: true,
    type: true,
    user: true,
    comment: true,
    amount: true,
    status: true
}
export type TransactionSettingsType = {
    transaction_timestamp?: boolean
    id?: boolean
    type?: boolean
    tx_hash?: boolean
    amount?: boolean
    source_address?: boolean
    target_address?: boolean
    status?: boolean
    comment?: boolean
    user?: boolean
}
export type NamesTransactionType = 'transaction_timestamp'
    | "id"
    | "source_address"
    | "target_address"
    | "tx_hash"
    | "type"
    | "user"
    | "comment"
    | "amount"
    | "status"

export const transactionColumnOrder = ['transaction_timestamp', "id", "source_address", "target_address", "tx_hash", "type", "user", "comment", "amount", "status"]

export type TransactionColumnSizeType = {
    transaction_timestamp: number
    id: number
    type: number
    tx_hash: number
    amount: number
    source_address: number
    target_address: number
    status: number
    comment: number
    user: number
    entity: number
}