import {MenuItem, Menu, MenuProps} from "@mui/material"
import React, {useState, MouseEvent, useEffect} from "react";
import {ReactComponent as Usdt} from "../../assets/images/usdt.svg";
import {ReactComponent as Arrow} from "../../assets/images/Arrow.svg";
import './BalanceMenu.scss';
import styled from "@emotion/styled";
import {useAppDispatch} from "../../hooks/useAppDispatch";
import {fetchBalanceTC} from "../../state/reducers/balance-reducer";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {numberWithSpaces} from "../../utils/utils";
import {ModalAddBalance} from "../modalAddBalance/ModalAddBalance";
import {useTranslation} from "react-i18next";


const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({theme}) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: 1,
        minWidth: 350,
        boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.25) !important'
    },
}));


export const BalanceMenu = (props: any) => {

    const frozen_balance = useSelector<AppRootStateType, number>(state => state.balance.state.frozen_balance);
    const balance = useSelector<AppRootStateType, number>(state => state.balance.state.balance);
    const total_balance = useSelector<AppRootStateType, number>(state => state.balance.state.total);
    const [showModal, setShowModal] = useState(false)

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const dispatch = useAppDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(fetchBalanceTC());
    }, []);

    useEffect(() => {
        let timer1 = setInterval(() => {
            dispatch(fetchBalanceTC());
        }, 60 * 1000)
        return () => {
            clearTimeout(timer1);
        };
    }, []);

    return (
        <div className={`${'Button'} ${open && 'ButtonCollapsed'}`}>
            <ModalAddBalance handleToggle={() => setShowModal(!showModal)} show={showModal}/>
            <button className={'Click'} onClick={handleClick}></button>
            <span className={'Currency'}>
                 <Usdt/>
            </span>
            <p>
                {numberWithSpaces(total_balance.toFixed(2))} USDT
            </p>
            <Arrow/>
            <StyledMenu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                className={'Menu'}
                onClose={handleClose}

            >
                <MenuItem onClick={handleClose} className={'AvailableBalance'}>
                    <p>{t("balance.available_balance")}</p>
                    <p>{balance && numberWithSpaces(balance.toFixed(2))} USDT</p>
                </MenuItem>
                <MenuItem onClick={handleClose} className={'FrozenBalance'}>
                    <p>{t("balance.frozen_balance")}</p>
                    <p>{frozen_balance && numberWithSpaces(frozen_balance.toFixed(2))} USDT</p>
                </MenuItem>
                <MenuItem onClick={handleClose} className={'TotalItem'}>
                    <p>{t("balance.total_balance")}</p>
                    <p>{total_balance && numberWithSpaces(total_balance.toFixed(2))} USDT</p>
                </MenuItem>
                <MenuItem onClick={handleClose} className={'FrozenBalance'}>
                    <button className={'ReplenishButton'} onClick={() => setShowModal(!showModal)}>{t("balance.replenish")}</button>
                </MenuItem>
            </StyledMenu>
        </div>
    )
}