import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {PasswordTextField} from "../passwordTextField/PasswordTextField";

type PropsType = {
    formik: any
    password: string
    generatePassword: () => void
}

export const PasswordFilter: FC<PropsType> = ({formik, password, generatePassword}) => {
    const {t} = useTranslation();

    return (
        <>
            <PasswordTextField title={t('filters.password')} error={formik.errors.password} readOnly={true}
                               formik={formik}
                               formikValue={'password'} generatedValue={password}
                               generatePassword={generatePassword}/>
        </>
    )
}