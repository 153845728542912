import style from "./DateComponent.module.scss";
import {FC} from "react";
import {ReactComponent as DateIcon} from "../../assets/images/date.svg";

import DatePicker, {registerLocale} from "react-datepicker";
import {ru} from "date-fns/locale/ru";
import {useTranslation} from "react-i18next";

registerLocale("ru", ru);

interface IDateComponentProps {
    startDate: Date | null | undefined
    endDate: Date | null | undefined
    setDateRange: (tr:[Date | null, Date | null] | null ) => void
}

export const DateComponent: FC<IDateComponentProps> = ({startDate, endDate, setDateRange}) => {
    const {t} = useTranslation();
    return (
        <div className={style.FilterBox}>
            <p>
                {t("filters.date_range")}
            </p>
            <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                isClearable={true}
                onChange={(update) => {
                    if (update === null) {
                        setDateRange(null);
                    }
                    setDateRange(update);
                }
                }

                className={style.DatePicker}
                locale="ru"
                placeholderText={t('notChosen')}
                showIcon
                clearButtonClassName={style.Clear}
                icon={<DateIcon className={style.DateIcon}/>}
            />
        </div>
    )
}