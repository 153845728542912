import {BasicSelect} from "../basicSelect/BasicSelect";
import React, {FC} from "react";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {SettingType} from "../../state/reducers/setting-reducer";
import {useTranslation} from "react-i18next";
import MultipleSelect2 from "../multipleSelect/MultipleSelect2";

type PropsType = {
    formik: any
    type?: 'extended'
    typeOfSelect?: 'multiple' | 'basic'
}

export const PaymentMethodFilter: FC<PropsType> = ({formik, type, typeOfSelect}) => {
    const {t} = useTranslation();
    const methods = useSelector<AppRootStateType, SettingType[]>(state => state.settings.methods);
    const defaultVal = t('notChosen');

    const defaulFiltertObj = {
        value: defaultVal, name: defaultVal
    }
    const items = methods && type !== 'extended' ? [defaulFiltertObj, ...methods] : methods

    return (
        <>
            {methods && (typeOfSelect !== 'multiple' ?
                    <BasicSelect label={t("filters.payment_method")} title={'method'} formik={formik}
                                 items={items}/>
                    : <MultipleSelect2 setFieldValue={formik.setFieldValue} title={t("filters.payment_method")}
                                       formikValue={'method'}
                                       formik={formik} items={methods}/>
            )}
        </>
    )
}